import { DescriptionList, Flex } from '@applyboard/crystal-ui'
import { LanguageExamHistoryRecord } from '../ApplicationDocuments'

type LanguageOtherExamHistoryEntryFieldsProps = {
  record: LanguageExamHistoryRecord
}

export function LanguageOtherExamHistoryEntryFields({
  record,
}: Readonly<LanguageOtherExamHistoryEntryFieldsProps>) {
  return (
    <DescriptionList variant="free">
      <Flex direction="column" gap={12} pb={4}>
        <Flex direction="column">
          <DescriptionList.Term>Description</DescriptionList.Term>
          <DescriptionList.Details>{record.context.description || '---'}</DescriptionList.Details>
        </Flex>
      </Flex>
    </DescriptionList>
  )
}
