import { Button, Dialog, Flex, Text } from '@applyboard/crystal-ui'

export function ChangeLanguageTestDialog({
  currentTestLabel,
  isOpen,
  newTestLabel,
  onCloseDialog,
  onConfirm,
}: {
  currentTestLabel: string
  isOpen: boolean
  newTestLabel: string
  /** called when test change IS NOT applied */
  onCloseDialog: (isOpen: boolean) => void
  /** called when test change IS applied */
  onConfirm: () => void
}) {
  return (
    <Dialog open={isOpen} onOpenChange={onCloseDialog}>
      <Dialog.Heading>Change Proof Type?</Dialog.Heading>
      <Dialog.Content>
        <Flex gap={4} direction="column">
          <Text variant={'bodyM'}>
            You are about to change the proof type
            {` from ${currentTestLabel}`}
            {newTestLabel.length ? ` to ${newTestLabel}` : ''}. Upon your confirmation all
            information provided
            {` for ${currentTestLabel}`} will get permanently erased.
          </Text>
          <Text variant={'bodyM'}>Do you want to change the proof type?</Text>
        </Flex>
      </Dialog.Content>
      <Dialog.Actions>
        <Dialog.CloseButton width="fill">Cancel</Dialog.CloseButton>
        <Button intent="primary" emphasis="outlined" width="fill" onClick={() => onConfirm()}>
          Change
        </Button>
      </Dialog.Actions>
    </Dialog>
  )
}
