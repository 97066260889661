import { CheckMarkOutlineIcon, ClockOutlineIcon, CloseOutlineIcon } from '@applyboard/ui-icons'

import { DecisionDocumentTags, DocumentTags } from '../../../../../utils/enums'
import { IconType, Intent } from '@applyboard/crystal-ui/lib/types/design'

export function getIcon(type: DecisionDocumentTags): { intent: Intent; icon: IconType } {
  switch (type) {
    case DocumentTags.PRE_OFFER_LETTER:
    case DocumentTags.FINAL_OFFER_LETTER:
      return { intent: 'positive', icon: CheckMarkOutlineIcon }
    case DocumentTags.WAITLIST_LETTER:
      return { intent: 'secondary', icon: ClockOutlineIcon }
    case DocumentTags.DECLINED_LETTER:
      return { intent: 'negative', icon: CloseOutlineIcon }
    default:
      return { intent: 'secondary', icon: ClockOutlineIcon }
  }
}
