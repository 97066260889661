import { useMutation } from '@tanstack/react-query'
import { ConfirmUserParams } from 'schools-domain-backend-utils'
import { ApiStudentRequest, request } from '../utils/request'

export function useSignUpConfirmation() {
  const { isPending, mutate } = useMutation({
    mutationFn: async (params: ConfirmUserParams) => {
      return await request(
        new ApiStudentRequest(`/pre/users/${params.username}/confirm`, {
          method: 'POST',
          body: {
            temporaryPassword: params.temporaryPassword,
            newPassword: params.newPassword,
          },
        }),
        {
          isExpectedResponse: (res): res is unknown => true,
        },
      )
    },
  })

  return {
    isConfirmingSignUp: isPending,
    signUpConfirmation: mutate,
  }
}
