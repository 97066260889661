import { useApplicationFormContext } from './ApplicationForm'
import { FileData } from 'applications-types-lib'
import { GenericError, additionalDocumentTypeOptions, hasApplicationFee } from '../../../utils'
import { JsonApiError } from '../../../utils/errors'
import { ApplicationFormCard } from './ApplicationFormCard'
import { Flex } from '@applyboard/crystal-ui'
import { useUpdateApplication } from '../../../hooks'
import { DocumentTags } from '../../../utils/enums'
import { getFilesOfType } from './utils'
import { FormTab } from './FormTab'
import { ApplicationFormsProps } from './ApplicationForms'
import { useGetDestinationCountry } from '../../../hooks/useGetDestinationCountry'

export function ApplicationFormWrapper(props: ApplicationFormsProps) {
  const { isUpdatingApplication, updateApplication } = useUpdateApplication({
    id: props.application.id,
  })
  const { resetFiles, pendingFileUploadState } = useApplicationFormContext()
  const { destinationCountryName } = useGetDestinationCountry()
  const { cardNumber, icon, title, formId } = getCardInformation({
    selectedTab: props.selectedTab,
    countryName: destinationCountryName,
  })

  const onError = (err: GenericError) => {
    if (err instanceof JsonApiError && err.code === 'applicationNotDraft')
      return props.onError?.(new Error(''))
    if (err instanceof Error) {
      props.onError?.(err)
    }
  }

  return (
    <Flex grow={1} direction="column">
      <ApplicationFormCard
        cardNumber={cardNumber}
        icon={icon}
        title={title}
        isLoading={isUpdatingApplication}
        disabled={props.disabled}
        formId={formId}
        focusRef={props.focusRef}
        submitButtonLabel={
          cardNumber === 6
            ? hasApplicationFee(props.application)
              ? 'Pay & Submit'
              : 'Save & Submit'
            : undefined
        }
        onPreviousTabButton={props.onPreviousTabButton}
        onSubmitApplication={
          cardNumber === 6
            ? () => {
                if (props.application) {
                  props.onSuccess({ data: props.application })
                }
                updateApplication(
                  {
                    files: pendingFileUploadState,
                  },
                  {
                    onSuccess: response => {
                      resetFiles(
                        getFilesOfType(
                          Object.keys(additionalDocumentTypeOptions) as DocumentTags[],
                          response.data?.attributes?.files as FileData,
                        ),
                      )
                      if (props.submitApplication) props.submitApplication() // typescript is confused...
                    },
                    onError,
                  },
                )
              }
            : undefined
        }
      >
        <FormTab
          application={props.application}
          disabled={props.disabled}
          onError={onError}
          onSuccess={props.onSuccess}
          selectedTab={props.selectedTab}
          updateApplication={updateApplication}
          submitApplication={props.submitApplication}
          formId={formId}
        />
      </ApplicationFormCard>
    </Flex>
  )
}

function getCardInformation(params: {
  selectedTab: ApplicationFormsProps['selectedTab']
  countryName?: string
}) {
  switch (params.selectedTab) {
    case 7:
    case 6:
      const otherRequiredDocumentsFormId = crypto.randomUUID()

      return {
        cardNumber: 6,
        icon: '📄',
        title: 'Other Required Documents',
        formId: otherRequiredDocumentsFormId,
      }
    case 5:
      const languageProficiencyFormId = crypto.randomUUID()

      return {
        cardNumber: 5,
        icon: '🗣️',
        title: 'Language Proficiency',
        formId: languageProficiencyFormId,
      }
    case 4:
      const educationHistoryFormId = crypto.randomUUID()

      return {
        cardNumber: 4,
        icon: '📚',
        title: 'Education History',
        formId: educationHistoryFormId,
      }
    case 3:
      const statusAndCitizenshipFormId = crypto.randomUUID()

      return {
        cardNumber: 3,
        icon: '🌎',
        title: `Citizenship and ${params.countryName || 'Destination Country'} Status`,
        formId: statusAndCitizenshipFormId,
      }
    case 2:
      const contactInformationFormId = crypto.randomUUID()

      return {
        cardNumber: 2,
        icon: '☎️',
        title: 'Contact Information',
        formId: contactInformationFormId,
      }
    default:
      const personalInformationFormId = crypto.randomUUID()

      return {
        cardNumber: 1,
        icon: '👤',
        title: 'Personal Information',
        formId: personalInformationFormId,
      }
  }
}
