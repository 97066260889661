import { Flex, TextInput, createForm } from '@applyboard/crystal-ui'
import { useProgramFilterSearchParams } from '../../../hooks'
import { useSearchParams } from 'react-router-dom'

import { ProgramFiltersDialog } from './ProgramFilterDialog'

type FormFields = {
  campuses: string[]
  startDates: string[]
  statuses: string[]
  q: string
}

const { Form, Field, useSetFieldValues, useGetFieldValues } = createForm<FormFields>()
export function ProgramFilters() {
  const { filters, updateFilters } = useProgramFilterSearchParams()
  const formId = crypto.randomUUID()

  return (
    <Flex gap={3} justify="between">
      <Flex.Item basis={{ xs: '100%' }}>
        <Form
          id={formId}
          defaultValues={{
            campuses: filters.campuses,
            startDates: filters.startDates,
            statuses: filters.statuses,
            q: filters.q,
          }}
          onSubmit={data => {
            updateFilters(data)
          }}
        >
          <ProgramNameTextInput />
        </Form>
      </Flex.Item>
      <ProgramFiltersDialog />
    </Flex>
  )
}

export function ProgramNameTextInput() {
  const [, setSearchParams] = useSearchParams()
  const setFieldValues = useSetFieldValues()
  const getFieldValues = useGetFieldValues()

  function submitForm() {
    const data = getFieldValues()
    setSearchParams(data)
  }
  function onKeyDownProgramName(key: React.KeyboardEvent<HTMLInputElement>['key']) {
    if (key === 'Enter') {
      submitForm()
    }
  }

  return (
    <Field
      as={TextInput}
      aria-label="Program Name"
      name="q"
      placeholder="Program name"
      onChange={value => {
        setFieldValues({
          q: value,
        })
        if (value === '') {
          submitForm()
        }
      }}
      size="md"
      intent="secondary"
      type="search"
      onKeyDown={onKeyDownProgramName}
    />
  )
}
