import { Button, Drawer, Flex } from '@applyboard/crystal-ui'
import { FilterOutlineIcon } from '@applyboard/ui-icons'
import { useState } from 'react'

import { useProgramFilterSearchParams } from '../../../hooks'
import { ProgramFiltersForm } from './ProgramFiltersForm'

export function ProgramFiltersDialog() {
  const { clearFilters } = useProgramFilterSearchParams()
  const [open, setOpen] = useState(false)
  const formId = crypto.randomUUID()

  return (
    <Drawer onOpenChange={setOpen} open={open} placement="top">
      <Drawer.Trigger>
        <Button aria-label="Filter Programs" leadIcon={FilterOutlineIcon} emphasis="outlined" />
      </Drawer.Trigger>
      <Drawer.Header>All Filters</Drawer.Header>
      <ProgramFiltersForm formId={formId} onSubmit={() => setOpen(false)} />
      <Drawer.Footer>
        <Flex gap={2}>
          <Button
            width="fill"
            emphasis="highlighted"
            onClick={() => {
              clearFilters()
              setOpen(false)
            }}
          >
            Clear all
          </Button>
          <Button type="submit" form={formId} width="fill" intent="primary">
            See programs
          </Button>
        </Flex>
      </Drawer.Footer>
    </Drawer>
  )
}
