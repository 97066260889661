import {
  Button,
  ButtonLink,
  Card,
  DescriptionList,
  Dialog,
  Flex,
  Text,
} from '@applyboard/crystal-ui'
import { format } from 'date-fns'
import { useState } from 'react'

import { IntakeSearchResource } from '@backend/resources/intake-search/types'
import { VersionedApplicationSanitized } from '@backend/sanitize/application'

import { ApplicationState } from '../../../../hooks'
import { convertTimelessDateStrToLocalDate } from '../../../../utils/convertTimelessDateStrToLocalDate'

type ProgramCardButtonProps = {
  disabled?: boolean
  loading?: boolean
  onClick: () => void
  intakeSearch: IntakeSearchResource['attributes']
  currentApplication?: VersionedApplicationSanitized['attributes']
}

export function ProgramCardButton(props: ProgramCardButtonProps) {
  const [open, setOpen] = useState(false)

  if (props.intakeSearch.applications?.length) {
    const activeApplication = props.intakeSearch.applications.find(
      application =>
        application.applicationState !== ApplicationState.DECLINED &&
        application.applicationState !== ApplicationState.INITIAL_OFFER,
    )

    if (activeApplication) {
      const href =
        activeApplication.applicationState === ApplicationState.DRAFT
          ? `/applications/new?applicationId=${activeApplication.id}`
          : `/applications/${activeApplication.id}`

      return (
        <ButtonLink intent="primary" emphasis="outlined" width="fill" href={href}>
          View Application
        </ButtonLink>
      )
    }
  }

  if (props.intakeSearch.intake.status === 'OPEN') {
    if (props.currentApplication) {
      const applicationSummary = {
        programName: props.currentApplication.programSelected?.program?.name ?? '',
        intakeTermDate: props.currentApplication.programSelected?.programIntakeTerm?.startDate
          ? format(
              convertTimelessDateStrToLocalDate(
                props.currentApplication.programSelected?.programIntakeTerm?.startDate,
              ),
              'MMM yyyy',
            )
          : '',
        campusName: props.currentApplication.programSelected?.campus?.name ?? '',
      }

      return (
        <Dialog size="md" open={open} onOpenChange={setOpen}>
          <Dialog.Trigger>
            <Button intent="primary" emphasis="outlined" width="fill" disabled={props.disabled}>
              Apply
            </Button>
          </Dialog.Trigger>
          <Dialog.Heading>Confirm your update</Dialog.Heading>
          <Dialog.Content>
            <Text as="p">This action will do the following update to your application:</Text>
            <Card variant="highlighted">
              <DescriptionList aria-label="Current program information" variant="free">
                <Flex direction="column">
                  <Text intent="primary" variant="titleS">
                    {`Current:`}
                  </Text>
                  <Flex gap={1}>
                    <DescriptionList.Term>Program:</DescriptionList.Term>
                    <DescriptionList.Details>
                      {applicationSummary.programName}
                    </DescriptionList.Details>
                  </Flex>
                  <Flex gap={1}>
                    <DescriptionList.Term>Intake:</DescriptionList.Term>
                    <DescriptionList.Details>
                      {applicationSummary.intakeTermDate}
                    </DescriptionList.Details>
                  </Flex>
                  <Flex gap={1}>
                    <DescriptionList.Term>Campus:</DescriptionList.Term>
                    <DescriptionList.Details>
                      {applicationSummary.campusName}
                    </DescriptionList.Details>
                  </Flex>
                </Flex>
              </DescriptionList>
              <DescriptionList aria-label="Current program information" variant="free">
                <Flex direction="column">
                  <Text intent="primary" variant="titleS">
                    {`New:`}
                  </Text>
                  <Flex gap={1}>
                    <DescriptionList.Term>Program:</DescriptionList.Term>
                    <DescriptionList.Details>
                      {props.intakeSearch.program.name}
                    </DescriptionList.Details>
                  </Flex>
                  <Flex gap={1}>
                    <DescriptionList.Term>Intake:</DescriptionList.Term>
                    <DescriptionList.Details>
                      {format(
                        convertTimelessDateStrToLocalDate(props.intakeSearch.intakeTerm.startsOn),
                        'MMM yyyy',
                      )}
                    </DescriptionList.Details>
                  </Flex>
                  <Flex gap={1}>
                    <DescriptionList.Term>Campus:</DescriptionList.Term>
                    <DescriptionList.Details>
                      {props.intakeSearch.campus.name}
                    </DescriptionList.Details>
                  </Flex>
                </Flex>
              </DescriptionList>
            </Card>
            <Dialog.Actions>
              <Dialog.CloseButton intent="secondary" width={'fill'}>
                Cancel
              </Dialog.CloseButton>
              <Button
                intent="primary"
                onClick={props.onClick}
                width={'fill'}
                loading={props.loading}
              >
                Confirm & update
              </Button>
            </Dialog.Actions>
          </Dialog.Content>
        </Dialog>
      )
    }

    return (
      <Button
        intent="primary"
        emphasis="outlined"
        width="fill"
        onClick={props.onClick}
        disabled={props.disabled}
        loading={props.loading}
      >
        Apply
      </Button>
    )
  }

  return (
    <Flex pb={5} justify="center">
      <Text variant="labelL" contrast="low">
        Not Available
      </Text>
    </Flex>
  )
}
