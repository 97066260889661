// any uses of these fake "vars" will be replaced with relevant strings or undefined by webpack during build time
import { parseJsonIfPresent } from '../utils/parseJsonIfPresent'

declare var __CONFIG__FLYWIRE_CONFIG: string | undefined

export type FlywireConfigType = {
  // Checkout integration configuration
  provider: string
  env: string
  recipient: string
  payment_destination: string
  locale: string
}

const flywareSetup = {
  getConfig: (): FlywireConfigType => {
    const localDevConfig = {
      provider: 'embed2.0',
      env: 'demo',
      recipient: 'PPY',
      payment_destination: 'semcad',
      locale: 'en',
    }
    return typeof __CONFIG__FLYWIRE_CONFIG !== 'undefined'
      ? parseJsonIfPresent(__CONFIG__FLYWIRE_CONFIG) || localDevConfig
      : localDevConfig
  },
}

export default flywareSetup
