import { useState } from 'react'
import styled from '@emotion/styled'
import { Button, Flex, Heading, Text, useToasts } from '@applyboard/crystal-ui'

import { useForgotPassword } from '../../hooks'
import { ForgotPasswordConfirmationPage } from './ForgotPasswordConfirmationPage'

const TextWrapper = styled.div(() => ({
  maxWidth: 450,
}))

export function ForgotPasswordExpiredPage(props: { username: string }) {
  const toast = useToasts()
  const [success, setSuccess] = useState<boolean>(false)
  const { forgotPassword, isLoadingForgotPassword } = useForgotPassword()

  if (success) {
    return <ForgotPasswordConfirmationPage username={props.username} />
  }

  return (
    <Flex direction="column" gap={10}>
      <Flex direction="column" align="center" gap={6}>
        <img src="/alert.svg" alt="Email illustration" />
        <Heading level={1} variant="headlineM">
          Password reset link expired
        </Heading>
        <TextWrapper>
          <Text align="center">
            Unfortunately, your password reset link we provided has expired. Try sending link again.
          </Text>
        </TextWrapper>
      </Flex>
      <Button
        intent="primary"
        variant="outlined"
        width="fill"
        onClick={() => {
          forgotPassword(
            {
              email: props.username,
            },
            {
              onSuccess: () => {
                toast.positive('Reset password email sent')
                setSuccess(true)
              },
              onError: err => {
                if (err instanceof Error) {
                  toast.negative(err)
                }
              },
            },
          )
        }}
        loading={isLoadingForgotPassword}
      >
        Resend link
      </Button>
    </Flex>
  )
}
