import { useLocation, useSearchParams } from 'react-router-dom'
import { OnBoardingBox } from '../../components/OnBoardingBox'

import { ForgotPasswordExpiredPage } from './ForgotPasswordExpiredPage'
import { ForgotPasswordForm } from './ForgotPasswordForm'

export function ForgotPasswordPage() {
  const [searchParams] = useSearchParams()
  const expired = searchParams.get('expired')
  const { state } = useLocation()

  return (
    <OnBoardingBox>
      {expired === 'true' ? (
        <ForgotPasswordExpiredPage username={state?.username ?? ''} />
      ) : (
        <ForgotPasswordForm />
      )}
    </OnBoardingBox>
  )
}
