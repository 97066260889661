import { Button } from '@applyboard/crystal-ui'
import { DeleteOutlineIcon } from '@applyboard/ui-icons'
import { useState } from 'react'
import { DeleteApplicationDialog } from '../../components/Application'
import { RawListApplicationsResponse } from '../../hooks'

type DeleteApplicationButtonProps = {
  application: RawListApplicationsResponse['data'][number]
  onSuccess: () => void
}

export function DeleteApplicationButton(props: DeleteApplicationButtonProps) {
  const [showDialog, setShowDialog] = useState<boolean>(false)

  return (
    <>
      <Button
        intent="negative"
        emphasis="outlined"
        leadIcon={DeleteOutlineIcon}
        aria-label={`Delete ${props.application.attributes?.programSelected?.program?.name} application`}
        onClick={() => {
          setShowDialog(true)
        }}
      />

      <DeleteApplicationDialog
        open={showDialog}
        onOpenChange={setShowDialog}
        applicationId={props.application.id}
        onSuccess={props.onSuccess}
      />
    </>
  )
}
