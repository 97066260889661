import { Flex, MultiCombobox, createForm } from '@applyboard/crystal-ui'
import { useGetCampuses } from '../../../hooks/useGetCampuses'
import { Loading } from '../../../components/Loading'
import { format } from 'date-fns'
import { useGetIntakeTerms, useProgramFilterSearchParams } from '../../../hooks'
import { convertTimelessDateStrToLocalDate } from '../../../utils/convertTimelessDateStrToLocalDate'

type ProgramFiltersFormProps = {
  formId: string
  onSubmit: () => void
}

type FormFields = {
  campuses: string[]
  startDates: string[]
  statuses: string[]
  q: string
}

const { Form, Field } = createForm<FormFields>()

export function ProgramFiltersForm(props: ProgramFiltersFormProps) {
  const { filters, updateFilters } = useProgramFilterSearchParams()
  const { campuses, isLoadingCampuses } = useGetCampuses()
  const { intakeTerms, isLoadingIntakeTerms } = useGetIntakeTerms({
    isArchived: false,
  })

  if (isLoadingCampuses || isLoadingIntakeTerms) {
    return <Loading />
  }

  return (
    <Form
      id={props.formId}
      defaultValues={{
        campuses: filters.campuses,
        startDates: filters.startDates,
        statuses: filters.statuses,
        q: filters.q,
      }}
      onSubmit={data => {
        updateFilters(data)
        props.onSubmit()
      }}
    >
      <Flex direction="column" gap={6} pb={6}>
        <Field as={MultiCombobox} label="Campus" name="campuses">
          {campuses.map(campus => (
            <MultiCombobox.Option
              key={`campus-${campus.id}`}
              label={campus.attributes.name}
              value={campus.id}
            />
          ))}
        </Field>
        <Field as={MultiCombobox} label="Start" name="startDates">
          {intakeTerms?.data
            ? intakeTerms.data.map(intakeTerm => (
                <MultiCombobox.Option
                  key={`startDates-${intakeTerm.id}`}
                  label={format(
                    convertTimelessDateStrToLocalDate(intakeTerm.attributes.startsOn),
                    'MMM yyyy',
                  )}
                  value={intakeTerm.id}
                />
              ))
            : null}
        </Field>
        <Field as={MultiCombobox} label="Status" name="statuses">
          <MultiCombobox.Option label="Open" value="OPEN" />
          <MultiCombobox.Option label="Will Open" value="WILL_OPEN" />
          <MultiCombobox.Option label="Closed" value="CLOSED" />
        </Field>
      </Flex>
    </Form>
  )
}
