import { VersionedApplicationSanitized } from '@backend/sanitize/application'

import { useAuthMutation } from './auth'
import { ApiStudentRequest, request } from '../utils'

type SubmitApplicationProps = {
  id: string
}

type SubmitApplicationParams = undefined

type RawApplicationsResponse = {
  data: VersionedApplicationSanitized
}

export function useSubmitApplication(props: SubmitApplicationProps) {
  const { isPending, mutate } = useAuthMutation(async (params: SubmitApplicationParams) => {
    return await request<RawApplicationsResponse>(
      new ApiStudentRequest(`/applications/${props.id}/submit`, {
        method: 'POST',
      }),
      {
        isExpectedResponse: (res): res is RawApplicationsResponse => res,
      },
    )
  })

  return {
    isSubmittingApplication: isPending,
    submitApplication: mutate,
  }
}
