import { useQuery, useQueryClient } from '@tanstack/react-query'
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'
import { useAuthManager } from './useAuthManager'
import { useAuthToken } from './useAuthToken'
import { GenericError } from '../../utils'

export function useAuthQuery<
  TQueryFnData = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TQueryFnData, TQueryKey>,
  queryOptions?: Omit<
    UseQueryOptions<TQueryFnData, GenericError, TData, TQueryKey>,
    'queryKey' | 'queryFn'
  >,
): UseQueryResult<TData, Error> {
  const queryClient = useQueryClient()
  const { isActiveSession, isRefreshableSession } = useAuthToken()
  const { isRefreshingAccessToken, refreshAccessToken, signout } = useAuthManager()

  const useQueryResponse = useQuery({
    queryKey,
    queryFn: async context => {
      try {
        if (!isActiveSession()) {
          if (!isRefreshableSession()) throw new Error('Unable to refresh inactive session')
          await refreshAccessToken({ throwOnError: true })
        }
        return await queryFn(context)
      } catch (e) {
        queryClient.invalidateQueries()
        signout()
        throw new Error('Unable to refresh access token')
      }
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...queryOptions,
    enabled:
      queryOptions?.enabled === undefined
        ? !isRefreshingAccessToken
        : queryOptions.enabled && !isRefreshingAccessToken,
  })

  return useQueryResponse
}
