import { Card, Divider, Flex } from '@applyboard/crystal-ui'

import { ApplicationDetailsFile, ApplicationDetailsIntent } from './types'
import { ApplicationDetailCardActions } from './ApplicationDetailCardActions'
import { ApplicationDetailCardDescription } from './ApplicationDetailCardDescription'
import { ApplicationDetailFile } from '../ApplicationDetailFile'
import { ApplicationDetailCardIssued } from './ApplicationDetailCardIssued'
import { ApplicationDetailCardTitle } from './ApplicationDetailCardTitle'
import { ApplicationDetailCardHelper } from './ApplicationDetailCardHelper'
import { ApplicationDetailCardRejectDialogProps } from './ApplicationDetailCardActions/ApplicationDetailCardRejectDialog'
import { ApplicationDetailCardAcceptDialogProps } from './ApplicationDetailCardActions/ApplicationDetailCardAcceptDialog'
import { DecisionResponseType } from 'applications-types-lib/dist/src/backend/applications-api/resources/applications/student-decisions'
import { DocumentTags } from '../../../../utils/enums'

type ApplicationDetailCardProps = Readonly<{
  title: string
  issuedOn: Date | undefined
  hasOfferConditions: boolean
  file: ApplicationDetailsFile
  status?: DecisionResponseType
  statusChangedOn?: Date
  rejectDialog?: ApplicationDetailCardRejectDialogProps
  onOfferConditionsClick?: () => void
  acceptDialog?: ApplicationDetailCardAcceptDialogProps
  loading?: boolean
}>

function getIntentByStatus(status: DecisionResponseType): ApplicationDetailsIntent {
  switch (status) {
    case DecisionResponseType.REJECTED:
      return 'negative'
    case DecisionResponseType.ACCEPTED:
      return 'positive'
    default:
      return 'secondary'
  }
}

export function ApplicationDetailCard(props: ApplicationDetailCardProps) {
  return (
    <Card>
      <Flex direction="column">
        <Flex
          direction={{
            xs: 'column',
            lg: 'row',
          }}
          gap={4}
        >
          <Flex
            gap={3}
            align="center"
            justify={{
              xs: 'between',
              lg: 'start',
            }}
            grow={1}
            wrap
          >
            <ApplicationDetailCardTitle title={props.title} documentTag={props.file.documentTag} />
            <ApplicationDetailCardIssued
              issuedOn={props.issuedOn}
              decisionDocumentTag={props.file.documentTag}
            />
          </Flex>

          <Flex.Item basis={{ xs: '100%', lg: '43%' }}>
            <ApplicationDetailFile url={props.file.url} name={props.file.name} />
          </Flex.Item>
        </Flex>

        <Divider />

        <Flex
          gap={{ lg: 4 }}
          direction={{ xs: 'column', lg: 'row' }}
          align={{ lg: 'center' }}
          justify={{ xs: 'between', lg: 'between' }}
          pb={{ lg: props.status ? 0 : 4 }}
        >
          {props.file.documentTag === DocumentTags.PRE_OFFER_LETTER ? (
            <Flex.Item basis={{ lg: '53%' }}>
              <ApplicationDetailCardDescription
                status={props.status}
                statusChangedOn={props.statusChangedOn}
              />
            </Flex.Item>
          ) : null}
          {(props.acceptDialog && props.rejectDialog) || props.onOfferConditionsClick ? (
            <Flex
              direction={{ xs: 'column', lg: 'row' }}
              justify="end"
              gap={{ xs: 4, lg: 2 }}
              pb={{ xs: 4, lg: 0 }}
            >
              <ApplicationDetailCardActions
                rejectDialog={props.rejectDialog}
                onOfferConditionsClick={props.onOfferConditionsClick}
                loading={props.loading}
                acceptDialog={props.acceptDialog}
              />
            </Flex>
          ) : null}
        </Flex>

        <Flex>
          <ApplicationDetailCardHelper
            decisionDocumentTag={props.file.documentTag}
            status={props.status}
            hasCondition={props.hasOfferConditions}
          />
        </Flex>
      </Flex>
    </Card>
  )
}
