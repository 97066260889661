import { useMutation } from '@tanstack/react-query'
import { ApiStudentRequest, request } from '../utils/request'

type ResendConfirmationProps = {
  id: string
}

export function useResendSignUpConfirmation(props: ResendConfirmationProps) {
  const { isPending, mutate } = useMutation({
    mutationFn: async () => {
      return await request(
        new ApiStudentRequest(`/pre/users/${props.id}/resend-confirmation`, {
          method: 'POST',
          body: {
            id: props.id,
          },
        }),
        {
          isExpectedResponse: (res): res is unknown => true,
        },
      )
    },
  })

  return {
    isSendingConfirmation: isPending,
    resendConfirmation: mutate,
  }
}
