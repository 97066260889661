import initializers from './config/initializers'

import { CrystalProvider } from '@applyboard/crystal-ui'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ProtectedRoute } from './components/ProtectedRoute'
import {
  ApplicationsListPage,
  CreateApplicationOverviewPage,
  CreateApplicationPage,
  ForgotPasswordPage,
  ForgotPasswordResetPage,
  LoginPage,
  NotFoundPage,
  OnboardingPage,
  ProfilePage,
  SignUpConfirmationPage,
  SignUpPage,
  ViewApplicationPage,
} from './pages'

initializers.init()

const queryClient = new QueryClient()

function App() {
  return (
    <HelmetProvider>
      <Helmet>
        <script src="https://payment.flywire.com/assets/js/checkout.js"></script>
      </Helmet>
      <QueryClientProvider client={queryClient}>
        <CrystalProvider>
          <BrowserRouter>
            <Routes>
              <Route element={<ProtectedRoute />}>
                <Route path="/" element={<ApplicationsListPage />} />
                <Route path="/onboarding" element={<OnboardingPage />} />
                <Route path="/profile" element={<ProfilePage />} />
                <Route path="/applications/overview" element={<CreateApplicationOverviewPage />} />
                <Route path="/applications/new" element={<CreateApplicationPage />} />
                <Route path="/applications/:id" element={<ViewApplicationPage />} />
              </Route>
              <Route element={<ProtectedRoute isSignupLoginRoute />}>
                <Route path="/signup/:username/confirm" element={<SignUpConfirmationPage />} />
                <Route path="/signup" element={<SignUpPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route
                  path="/forgot-password/:username/reset"
                  element={<ForgotPasswordResetPage />}
                />
                <Route path="/forgot-password" element={<ForgotPasswordPage />} />
              </Route>
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </BrowserRouter>
        </CrystalProvider>
      </QueryClientProvider>
    </HelmetProvider>
  )
}

export default App
