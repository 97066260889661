/* eslint-disable */
import newrelicSetup from './newrelic-setup'
import flywireSetup, { FlywireConfigType } from './flywire-setup'
import * as React from 'react'

export type InitConfig = {
  flywireConfig: FlywireConfigType
}
export const InitContext = React.createContext<InitConfig>({
  flywireConfig: flywireSetup.getConfig(),
})

export default {
  init: () => {
    // forcing webpack to include the imports in the build
    newrelicSetup
    flywireSetup
  },
}
