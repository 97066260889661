import { ButtonLink, Flex, Heading } from '@applyboard/crystal-ui'
import { PlusOutlineIcon } from '@applyboard/ui-icons'
import { Loading } from '../../components/Loading'
import { NavBars } from '../../components/NavBars'
import { useListApplications } from '../../hooks'
import { ApplicationsList } from './ApplicationsList'
import { EmptyApplicationList } from './EmptyApplicationList'

export function ApplicationsListPage() {
  const { isLoadingApplications, applications } = useListApplications()

  if (isLoadingApplications) {
    return (
      <NavBars>
        <Loading />
      </NavBars>
    )
  }

  return (
    <NavBars largePage>
      <Flex gap={6} direction="column">
        <Flex justify="between" align="center" gap={6} direction="row">
          <Heading level={1} variant="headlineL">
            My Applications
          </Heading>
          {applications?.length ? (
            <Flex hideBelow="md">
              <ButtonLink href="/applications/overview" intent="primary" leadIcon={PlusOutlineIcon}>
                Create New Application
              </ButtonLink>
            </Flex>
          ) : null}
        </Flex>
        {applications?.length ? (
          <ApplicationsList applications={applications} />
        ) : (
          <EmptyApplicationList />
        )}
        {applications?.length ? (
          <Flex hideAbove="md" pt={2}>
            <ButtonLink
              width="fill"
              href="/applications/overview"
              intent="primary"
              leadIcon={PlusOutlineIcon}
            >
              Create New Application
            </ButtonLink>
          </Flex>
        ) : null}
      </Flex>
    </NavBars>
  )
}
