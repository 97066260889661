import { ButtonLink } from '@applyboard/crystal-ui'
import { DocumentCheckOutlineIcon } from '@applyboard/ui-icons'

import { ApplicationDetailsFile } from '../ApplicationDetailCard/types'

type ApplicationDetailFileProps = Readonly<
  Omit<ApplicationDetailsFile, 'documentTag' | 'hasOpenDocumentRequests'>
>

export function ApplicationDetailFile(props: ApplicationDetailFileProps) {
  return (
    <ButtonLink
      aria-label={`Download file: ${props.name}`}
      href={props.url}
      mode="override_user_choice_and_open_in_new_tab"
      leadIcon={DocumentCheckOutlineIcon}
      variant="highlighted"
      intent="primary"
      width="fill"
      size="lg"
    >
      {props.name}
    </ButtonLink>
  )
}
