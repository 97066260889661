import {
  Button,
  Dialog,
  Divider,
  Flex,
  Notice,
  Select,
  Text,
  createForm,
  useToasts,
} from '@applyboard/crystal-ui'
import { ThumbDownOutlineIcon } from '@applyboard/ui-icons'
import { useState } from 'react'

export type ApplicationDetailCardRejectDialogForm = {
  reason: string
}

export type ApplicationDetailCardRejectDialogProps = Readonly<{
  onSubmit: (params: {
    onSuccess: () => void
    onError: (err: Error) => void
    data: ApplicationDetailCardRejectDialogForm
  }) => void
  loading?: boolean
}>

const { Form, Field } = createForm<ApplicationDetailCardRejectDialogForm>()

export function ApplicationDetailCardRejectDialog(props: ApplicationDetailCardRejectDialogProps) {
  const toast = useToasts()
  const [open, setOpen] = useState(false)
  const formId = crypto.randomUUID()

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <Dialog.Trigger>
        <Button
          intent="secondary"
          emphasis="outlined"
          width="fill"
          leadIcon={ThumbDownOutlineIcon}
          loading={props.loading}
        >
          Reject
        </Button>
      </Dialog.Trigger>
      <Dialog.Heading>Make Decision</Dialog.Heading>
      <Dialog.Content>
        <Flex direction="column">
          <Flex direction="column" gap={4}>
            <Text variant="labelL">
              Difficult decision! You are about to reject your acceptance.
            </Text>
            <Notice intent="negative" width="fill">
              Are you sure you want to reject the offer? Once rejected, the application will be
              terminated.
            </Notice>
          </Flex>
          <Divider />
          <Form
            id={formId}
            defaultValues={{
              reason: '',
            }}
            onSubmit={data => {
              props.onSubmit({
                onSuccess: () => {
                  toast.positive('Successfully rejected pre-offer letter.')
                  setOpen(false)
                },
                onError: err => {
                  toast.negative(new Error(err.message))
                },
                data,
              })
            }}
          >
            <Flex direction="column" gap={4}>
              <Field
                as={Select}
                name="reason"
                label="Reason for rejection"
                appearance="styled"
                emphasis="highlighted"
                required="Please select a reason"
              >
                <Select.Option value="No longer interested" label="No longer interested" />
                <Select.Option value="Accepted another offer" label="Accepted another offer" />
                <Select.Option value="Personal reasons" label="Personal reasons" />
                <Select.Option value="Financial constraints" label="Financial constraints" />
              </Field>
            </Flex>
          </Form>
        </Flex>
      </Dialog.Content>
      <Dialog.Actions>
        <Dialog.CloseButton width="fill" disabled={props.loading}>
          Cancel
        </Dialog.CloseButton>
        <Button width="fill" intent="primary" form={formId} type="submit" loading={props.loading}>
          Reject
        </Button>
      </Dialog.Actions>
    </Dialog>
  )
}
