// `request` function extracted from ui-utils.
import axios, { AxiosResponse, AxiosError } from 'axios'
import { IRequest } from './IRequest'
import { JsonApiError, HttpError, NetworkError, UnexpectedResponseError } from '../errors'

export type RequestParams<T> = {
  /**
   * The response that is used if a 404 is received.  The api sometimes responds
   * with a 404 if there is 0 records at a collection endpoint.  If not
   * provided, `request` will throw an error if 404 is received
   */
  defaultResponse?: T

  /**
   * A type-guard that should return true if the response is roughly the
   * expected shape.  Using this is a best practice because it will prevent
   * the rest of the app from trying to use an unexpected response.
   *
   * If the response is unexpected, `request` will throw an error that can be
   * handled like any other error
   */
  isExpectedResponse: (res: any) => res is T

  /**
   * Specifies what action to take when the API responds
   * with a redirect response. The default is to 'follow' which
   * will cause another API call to fired automatically.
   * The redirect option is documented here: https://developer.mozilla.org/en-US/docs/Web/API/fetch
   */
  handleRedirect?: 'follow' | 'error' | 'manual'
}

export async function request<T = unknown>(
  endpoint: IRequest,
  params: RequestParams<T>,
): Promise<T> {
  let request = endpoint
  const axiosObj = {
    url: request.url,
    method: request.method,
    headers: Object.fromEntries(request.headers),
    data: request.body,
  }

  let res: AxiosResponse

  try {
    res = await axios(axiosObj)
  } catch (err) {
    if (err instanceof AxiosError && err.response?.status) {
      if (err.response.status === 404 && params.defaultResponse) {
        return params.defaultResponse
      }

      if (err.response?.data?.errors?.length) {
        if (err.response.data.errors[0]?.meta?.errorData?.errors?.[0]) {
          throw new JsonApiError(
            err.response.data.errors[0]?.meta?.errorData?.errors?.[0].status,
            err.response.data.errors[0]?.meta?.errorData?.errors?.[0].detail,
            err.response.data.errors[0]?.meta?.errorData?.errors?.[0].code,
          )
        }
        throw new JsonApiError(
          err.response.status,
          err.response.data.errors[0]?.detail ?? 'Something went wrong',
          err.response.data.errors[0]?.code,
        )
      }

      throw new HttpError(err.response?.status, err.response?.statusText || err.message)
    }

    throw new NetworkError()
  }

  if (!params.isExpectedResponse(res.data)) {
    throw new UnexpectedResponseError(res.status, 'Unexpected response')
  }

  return res.data
}
