import { Button, Dialog, Flex, Heading, Notice, Text } from '@applyboard/crystal-ui'
import { Dispatch, SetStateAction } from 'react'

export function SubmitApprovalDialog(props: {
  showSubmitApprovalDialog: boolean
  setShowSubmitApprovalDialog: Dispatch<SetStateAction<boolean>>
  onSubmitConfirmation: () => void
  isLoading: boolean
}) {
  if (!props.showSubmitApprovalDialog) {
    return null
  }

  return (
    <>
      <Flex hideAbove="sm" direction="column" gap={10} grow={1}>
        <Heading level={1} variant="headlineL" align="center">
          Submit Application?
        </Heading>
        <Flex direction="column" gap={4} align="center">
          <Text>Are you sure you want to save and submit your application to the school?</Text>
        </Flex>
        <Flex pt={1} direction="column" gap={4}>
          <>
            <Button
              onClick={() => {
                props.onSubmitConfirmation()
              }}
              loading={props.isLoading}
              intent="primary"
              width="fill"
            >
              Submit
            </Button>
            <Button
              onClick={() => {
                props.setShowSubmitApprovalDialog(false)
              }}
              emphasis="highlighted"
              width="fill"
            >
              Cancel
            </Button>
          </>
        </Flex>
      </Flex>

      <Dialog
        onOpenChange={open => {
          props.setShowSubmitApprovalDialog(open)
        }}
        open={props.showSubmitApprovalDialog}
      >
        <Dialog.Heading>Ready to submit?</Dialog.Heading>
        <Dialog.Content>
          <Flex direction="column" justify="center" align="center" gap={4}>
            <Notice actionText="" intent="primary" width="fill">
              Please note that you will not be able to make any changes to your application after
              submission.
            </Notice>
            <img src="/hands-high-five.svg" alt="High Five" width="300px" height="300px" />
            <Text variant="bodyM">Well done! Your application is ready for submission.</Text>
          </Flex>
        </Dialog.Content>
        <Dialog.Actions>
          <Dialog.CloseButton width="fill">Cancel</Dialog.CloseButton>
          <Button
            onClick={() => {
              props.onSubmitConfirmation()
            }}
            loading={props.isLoading}
            width="fill"
            intent="primary"
          >
            Submit
          </Button>
        </Dialog.Actions>
      </Dialog>
    </>
  )
}
