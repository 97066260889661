import { Button, Dialog, Flex, Text, useToasts } from '@applyboard/crystal-ui'
import { useDeleteApplication } from '../../../hooks'
import { useEffect, useState } from 'react'

type DeleteApplicationDialogProps = {
  focusRef?: React.RefObject<HTMLElement>
  open: boolean
  onOpenChange: (open: boolean) => void
  applicationId: string
  onSuccess: () => void
}

export function DeleteApplicationDialog(props: DeleteApplicationDialogProps) {
  const { deleteApplication, isDeletingApplication } = useDeleteApplication()
  const toast = useToasts()
  const [recentlyClosed, setRecentlyClosed] = useState(false)

  useEffect(() => {
    if (recentlyClosed && props.focusRef?.current) {
      props.focusRef.current.focus()
      setRecentlyClosed(false)
    }
  }, [recentlyClosed, props.focusRef])

  return (
    <Dialog
      open={props.open}
      onOpenChange={open => {
        if (!isDeletingApplication) {
          props.onOpenChange(open)
          if (!open) {
            setRecentlyClosed(true)
          }
        }
      }}
    >
      <Dialog.Heading>Discard draft?</Dialog.Heading>
      <Dialog.Content>
        <Flex gap={4} direction="column">
          <Text>
            You are about to discard your draft application. Once you confirm, this action cannot be
            reverted.
          </Text>
          <Text>Are you sure you want to discard your draft?</Text>
        </Flex>
      </Dialog.Content>
      <Dialog.Actions>
        <Dialog.CloseButton width="fill" disabled={isDeletingApplication}>
          Cancel
        </Dialog.CloseButton>
        <Button
          intent="negative"
          width="fill"
          loading={isDeletingApplication}
          onClick={() => {
            deleteApplication(
              {
                id: props.applicationId,
              },
              {
                onSuccess: () => {
                  props.onSuccess()
                  props.onOpenChange(false)
                },
                onError: err => {
                  if (err instanceof Error) {
                    toast.negative(new Error(err.message))
                  }
                },
              },
            )
          }}
        >
          Yes, discard
        </Button>
      </Dialog.Actions>
    </Dialog>
  )
}
