import { ButtonLink, Flex, Heading, Text } from '@applyboard/crystal-ui'
import styled from '@emotion/styled'
import { OnBoardingBox } from '../../components/OnBoardingBox'

export function ForgotPasswordCongratulationsCard() {
  return (
    <OnBoardingBox>
      <Flex gap={{ xs: 10, sm: 6 }} align="center" direction="column">
        <img src="/tada-illustration.svg" alt="Congratulations" />
        <Flex gap={6} align="center" direction="column">
          <Heading level={1} variant="headlineM">
            Congratulations
          </Heading>
          <TextWrapper>
            <Text align="center">
              You successfully set a new password. You may now log into your account to access your
              application portal.
            </Text>
          </TextWrapper>
        </Flex>
        <ButtonLink intent="primary" href="/login">
          Log in
        </ButtonLink>
      </Flex>
    </OnBoardingBox>
  )
}

const TextWrapper = styled.div(() => ({
  maxWidth: 435,
}))
