import { useMutation } from '@tanstack/react-query'
import { AuthStudentRequest, request } from '../utils'

interface ForgotPasswordParams {
  email: string
}

export function useForgotPassword() {
  const { isPending, mutate } = useMutation({
    mutationFn: async (params: ForgotPasswordParams): Promise<void> => {
      await request(
        new AuthStudentRequest(`/pre/users/${params.email}/password/reset`, {
          method: 'POST',
        }),
        {
          isExpectedResponse: (res): res is any => true,
        },
      )
    },
  })

  return {
    forgotPassword: mutate,
    isLoadingForgotPassword: isPending,
  }
}
