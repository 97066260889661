import { useUpdateApplication } from '../../../hooks'
import { GenericError } from '../../../utils'
import { AdditionalDocumentsTab } from './AdditionalDocumentsTab'
import { ApplicationFormsProps } from './ApplicationForms'
import { ContactInformationTab } from './ContactInformationTab'
import { EducationHistoryTab } from './EducationHistoryTab'
import { LanguageProficiencyTab } from './LanguageProficiencyTab'
import { PersonalInformationTab } from './PersonalInformationTab'
import { StatusAndCitizenshipTab } from './StatusAndCitizenshipTab'

export function FormTab(
  props: Omit<ApplicationFormsProps, 'onError'> & {
    onError: (err: GenericError) => void
    updateApplication: ReturnType<typeof useUpdateApplication>['updateApplication']
    formId: string
  },
) {
  switch (props.selectedTab) {
    case 7:
    case 6:
      return (
        <AdditionalDocumentsTab
          selectedTab={props.selectedTab}
          application={props.application}
          onSuccess={props.onSuccess}
          onError={props.onError}
          submitApplication={props.submitApplication}
          disabled={props.disabled}
          updateApplication={props.updateApplication}
          formId={props.formId}
        />
      )
    case 5:
      return (
        <LanguageProficiencyTab
          onSuccess={props.onSuccess}
          application={props.application}
          onError={props.onError}
          disabled={props.disabled}
          updateApplication={props.updateApplication}
          formId={props.formId}
        />
      )
    case 4:
      return (
        <EducationHistoryTab
          onSuccess={props.onSuccess}
          application={props.application}
          onError={props.onError}
          disabled={props.disabled}
          updateApplication={props.updateApplication}
          formId={props.formId}
        />
      )
    case 3:
      return (
        <StatusAndCitizenshipTab
          onSuccess={props.onSuccess}
          application={props.application}
          onError={props.onError}
          disabled={props.disabled}
          updateApplication={props.updateApplication}
          formId={props.formId}
        />
      )
    case 2:
      return (
        <ContactInformationTab
          onSuccess={props.onSuccess}
          application={props.application}
          onError={props.onError}
          disabled={props.disabled}
          updateApplication={props.updateApplication}
          formId={props.formId}
        />
      )
    default:
      return (
        <PersonalInformationTab
          onSuccess={props.onSuccess}
          application={props.application}
          onError={props.onError}
          disabled={props.disabled}
          updateApplication={props.updateApplication}
          formId={props.formId}
        />
      )
  }
}
