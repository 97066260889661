import { Intent } from '@applyboard/crystal-ui/lib/types/design'

import { DecisionResponseType } from 'applications-types-lib/dist/src/backend/applications-api/resources/applications/student-decisions'

export function getTagInformation(status: DecisionResponseType | undefined): {
  label: string
  intent: Intent
} {
  switch (status) {
    case 'ACCEPTED':
      return {
        label: 'Accepted',
        intent: 'positive',
      }
    case 'REJECTED':
      return {
        label: 'Rejected',
        intent: 'negative',
      }
    case 'PENDING':
    default:
      return {
        label: 'Pending',
        intent: 'warning',
      }
  }
}
