import { Card, Box, Table, ButtonLink, Text, Flex } from '@applyboard/crystal-ui'
import ApplicationStateTag from '../../components/Application/ApplicationStateTag/ApplicationStateTag'
import { RawListApplicationsResponse } from '../../hooks/useListApplications'
import { ApplicationState } from '../../hooks'
import { DeleteApplicationButton } from './DeleteApplicationButton'

type ApplicationsTableProps = {
  applications: RawListApplicationsResponse['data'] | null
  onDelete: (application: RawListApplicationsResponse['data'][number]) => void
}

export function ApplicationsTable(props: ApplicationsTableProps) {
  return (
    <Box hideBelow="md">
      <Card emphasis="elevated">
        <Box p={4}>
          <Table
            columns={[
              {
                accessorKey: 'id',
                header: 'App. ID',
                cell: (application: RawListApplicationsResponse['data'][0]) => {
                  return (
                    <Box p={4}>
                      <Text>{application.attributes?.humanReadableId}</Text>
                    </Box>
                  )
                },
              },
              {
                accessorKey: 'program',
                header: 'Program Name',
                cell: (application: RawListApplicationsResponse['data'][0]) => {
                  if (!application.attributes?.programSelected?.program?.id) {
                    return (
                      <Box p={4}>
                        <Text>No program selected</Text>
                      </Box>
                    )
                  }

                  return (
                    <Box p={4}>
                      <Text>
                        {application.attributes.programSelected.program.name ||
                          `Program ${application.attributes.programSelected.program.id}`}
                      </Text>
                    </Box>
                  )
                },
              },
              {
                accessorKey: 'intake',
                header: 'Intake Term',
                cell: (application: RawListApplicationsResponse['data'][0]) => {
                  return (
                    <Box p={4}>
                      <Text>
                        {application.attributes?.programSelected?.programIntakeTerm?.name || '--'}
                      </Text>
                    </Box>
                  )
                },
              },
              {
                accessorKey: 'status',
                header: 'Status',
                cell: (application: RawListApplicationsResponse['data'][0]) => {
                  return (
                    <Box p={4}>
                      <ApplicationStateTag
                        state={application.attributes?.applicationState}
                        hasOpenDocumentRequests={application.attributes?.hasOpenDocumentRequests}
                      />
                    </Box>
                  )
                },
              },
              {
                accessorKey: 'options',
                header: '',
                cell: (application: RawListApplicationsResponse['data'][0]) => {
                  if (application.attributes?.applicationState === ApplicationState.DRAFT) {
                    return (
                      <Flex gap={4}>
                        <ButtonLink
                          href={`/applications/new?applicationId=${application.id}`}
                          intent="primary"
                          emphasis="outlined"
                          width="fill"
                        >
                          Finish Application
                        </ButtonLink>

                        <Flex>
                          <DeleteApplicationButton
                            application={application}
                            onSuccess={() => props.onDelete(application)}
                          />
                        </Flex>
                      </Flex>
                    )
                  }

                  return (
                    <Flex gap={4}>
                      <ButtonLink
                        href={`/applications/${application.id}`}
                        intent="primary"
                        emphasis="highlighted"
                        width="fill"
                      >
                        View Application
                      </ButtonLink>
                      <Flex.Item basis="59px">{}</Flex.Item>
                    </Flex>
                  )
                },
              },
            ]}
            data={props.applications || []}
          />
        </Box>
      </Card>
    </Box>
  )
}
