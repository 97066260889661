import { Button, Flex, Heading, Text, useToasts } from '@applyboard/crystal-ui'
import styled from '@emotion/styled'

import { useForgotPassword } from '../../hooks'

const TextWrapper = styled.div(() => ({
  maxWidth: 450,
}))

export function ForgotPasswordConfirmationPage(props: { username: string }) {
  const toast = useToasts()
  const { forgotPassword, isLoadingForgotPassword } = useForgotPassword()

  return (
    <Flex direction="column" gap={10}>
      <Flex direction="column" align="center" gap={6}>
        <img src="/email-illustration.svg" alt="Email illustration" />
        <Heading level={1} variant="headlineM">
          Check your email
        </Heading>
        <TextWrapper>
          <Text align="center">
            We have sent a password reset link to your email with instructions. You might need to
            check your spam folder if you don’t see it.
          </Text>
        </TextWrapper>
      </Flex>
      <Button
        intent="primary"
        variant="outlined"
        width="fill"
        onClick={() => {
          forgotPassword(
            {
              email: props.username,
            },
            {
              onSuccess: () => {
                toast.positive('Reset password email sent')
              },
              onError: err => {
                if (err instanceof Error) {
                  toast.negative(err)
                }
              },
            },
          )
        }}
        loading={isLoadingForgotPassword}
      >
        Resend link
      </Button>
    </Flex>
  )
}
