import { Card, DescriptionList, Flex, GridAlpha } from '@applyboard/crystal-ui'
import { ReviewRequirementsButton } from './ReviewRequirementsButton'
import { hasApplicationFee } from '../../../utils'
import { StudentApplication } from '../types'

type MobileHeaderProps = {
  application: StudentApplication
}

export function MobileHeader(props: MobileHeaderProps) {
  const program = props.application?.attributes?.programSelected

  return (
    <Card emphasis="highlighted">
      <Flex direction="column" gap={4}>
        <DescriptionList aria-label="Application Details" variant="free">
          <GridAlpha columns="auto 1fr" columnGap={4} rowGap={4}>
            <DescriptionList.Term>Program name</DescriptionList.Term>
            <DescriptionList.Details>{program?.program?.name}</DescriptionList.Details>

            <DescriptionList.Term>Intake term</DescriptionList.Term>
            <DescriptionList.Details>{program?.programIntakeTerm?.name}</DescriptionList.Details>

            <DescriptionList.Term>Campus</DescriptionList.Term>
            <DescriptionList.Details>{program?.campus?.name ?? '--'}</DescriptionList.Details>

            <DescriptionList.Term>Application ID</DescriptionList.Term>
            <DescriptionList.Details>
              {props.application?.attributes?.humanReadableId ?? '--'}
            </DescriptionList.Details>

            <DescriptionList.Term>Application fee</DescriptionList.Term>
            <DescriptionList.Details>
              {hasApplicationFee(props.application)
                ? Intl.NumberFormat(undefined, {
                    style: 'currency',
                    currency: props.application?.attributes?.applicationFee?.currency || 'CAD',
                  }).format(props.application?.attributes?.applicationFee?.amount || 0)
                : 'No Fee'}
            </DescriptionList.Details>
          </GridAlpha>
        </DescriptionList>
        <Card.Divider />
        <Flex justify="center">
          <ReviewRequirementsButton programId={program?.program?.id as string} />
        </Flex>
      </Flex>
    </Card>
  )
}
