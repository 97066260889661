import { Card, Flex, Heading, Text } from '@applyboard/crystal-ui'
import { AdditionalDocumentsRequest } from './AdditionalDocumentsRequests'
import { StudentApplication } from '../types'
import { compact } from 'lodash'
import { parseISO } from 'date-fns'
import { DocumentsRequest } from './types'

type ApplicationRequestsProps = {
  application: StudentApplication
}

export function ApplicationRequests(props: ApplicationRequestsProps) {
  let notCompletedRequests: DocumentsRequest[] = []
  const notCompletedRequestStatuses: DocumentsRequest['requestStatus'][] = ['PENDING']
  let processedRequests: DocumentsRequest[] = []
  const processedRequestsStatuses: DocumentsRequest['requestStatus'][] = ['COMPLETED', 'DISMISSED']
  let otherRequests: DocumentsRequest[] = []

  Object.entries(props.application.attributes?.requestedDocuments || {}).forEach(
    ([id, request]) => {
      if (
        !request ||
        !request.requestedAt ||
        !request.requestedFromState ||
        !request.requestStatus
      ) {
        return
      }

      const currentRequest: DocumentsRequest = {
        id,
        requestedFromState: request.requestedFromState,
        requestStatus: request.requestStatus,
        requestedAt: parseISO(request.requestedAt),
        closedDate: request.closedAt ? parseISO(request.closedAt) : null,
        documents: compact(request.documents) || [],
        application: props.application,
      }

      if (notCompletedRequestStatuses.includes(currentRequest.requestStatus)) {
        notCompletedRequests.push(currentRequest)
      } else if (processedRequestsStatuses.includes(currentRequest.requestStatus)) {
        processedRequests.push(currentRequest)
      } else {
        otherRequests.push(currentRequest)
      }
    },
  )

  const sortByRequestDate = (a: DocumentsRequest, b: DocumentsRequest) => {
    let result = 0
    if (a && b && new Date(a.requestedAt) < new Date(b.requestedAt)) result = 1
    if (a && b && new Date(a.requestedAt) > new Date(b.requestedAt)) result = -1
    return result
  }

  const sortByClosingDate = (a: DocumentsRequest, b: DocumentsRequest) => {
    let result = 0
    if (a && a.closedDate && b && b.closedDate && new Date(a.closedDate) < new Date(b.closedDate))
      result = 1
    if (a && a.closedDate && b && b.closedDate && new Date(a.closedDate) > new Date(b.closedDate))
      result = -1
    return result
  }

  notCompletedRequests = notCompletedRequests.sort(sortByRequestDate)
  processedRequests = processedRequests.sort(sortByClosingDate)
  otherRequests = otherRequests.sort(sortByRequestDate)

  const sortedRequests = [...notCompletedRequests, ...processedRequests, ...otherRequests]

  if (!sortedRequests.length) {
    return (
      <Card emphasis="elevated" aria-label="Empty document request list">
        <Flex direction="column" align="center" gap={8} py={{ xs: 20, sm: 36 }} px={6}>
          <img src="/question-response.svg" width={160} alt="Abstracted question with response" />
          <Flex direction="column" align="center" gap={2}>
            <Heading level={2} align="center">
              No requests
            </Heading>
            <Text align="center" contrast="mid">
              If more information is required to complete the assessment of your application, you
              will be able to view and action requests here.
            </Text>
          </Flex>
        </Flex>
      </Card>
    )
  }

  return (
    <Flex grow={1} direction="column" gap={6}>
      {sortedRequests.map((data, index) => (
        <AdditionalDocumentsRequest key={index} {...data} application={props.application} />
      ))}
    </Flex>
  )
}
