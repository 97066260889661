import { useAuthQuery } from './auth'
import { ApiStudentRequest, request } from '../utils'
import { GetIntakeTermsResponse } from '@backend/students-api/routes/intake-terms/GET/types'

type GetIntakeTermsProps = {
  pageNumber?: number
  pageSize?: number
  requestCachingTag?: string | undefined
  isArchived?: boolean
}

export function useGetIntakeTerms(props?: GetIntakeTermsProps) {
  const selectedPageNumber = props?.pageNumber || 1
  const selectedPageSize = props?.pageSize || 300

  const filters = [`page[size]=${selectedPageSize}`, `page[number]=${selectedPageNumber}`]

  if (props?.isArchived !== undefined) {
    filters.push(`filter[archived]=${props.isArchived ? 't' : 'f'}`)
  }

  const { isLoading, isFetching, data } = useAuthQuery(
    ['intake-terms-list', selectedPageNumber, selectedPageSize, props?.requestCachingTag],
    async context => {
      return await request<GetIntakeTermsResponse>(
        new ApiStudentRequest(`/intake-terms?${filters.join('&')}`, {
          method: 'GET',
        }),
        {
          isExpectedResponse: (res): res is GetIntakeTermsResponse => res,
        },
      )
    },
    {
      refetchOnWindowFocus: false,
    },
  )

  return {
    isLoadingIntakeTerms: isLoading,
    isFetchingIntakeTerms: isFetching,
    intakeTerms: data,
  }
}
