import { Tag } from '@applyboard/crystal-ui'
import { IntakeSearchResource } from '@backend/resources/intake-search/types'

type IntakeStatusProps = {
  status: IntakeSearchResource['attributes']['intake']['status']
}

export function IntakeStatus(props: IntakeStatusProps) {
  switch (props.status) {
    case 'OPEN':
      return <Tag intent="positive">Open</Tag>
    case 'CLOSED':
      return <Tag intent="secondary">Closed</Tag>
    case 'WILL_OPEN':
      return <Tag intent="magic">Opening Soon</Tag>
    default:
      return null
  }
}
