import { GetProgramResponse } from '@backend/students-api/routes/programs/{id}/GET/types'
import { ApiStudentRequest, request } from '../utils/request'
import { useAuthQuery } from './auth'

interface GetProgramProps {
  id?: string
}

export function useGetProgram({ id }: GetProgramProps) {
  const { isLoading, data } = useAuthQuery(['program', id], async () => {
    if (!id) return undefined

    return await request<GetProgramResponse>(
      new ApiStudentRequest(`/programs/${id}`, {
        method: 'GET',
      }),
      { isExpectedResponse: (res): res is GetProgramResponse => res },
    )
  })

  return {
    isLoadingProgram: isLoading,
    program: data?.data,
  }
}
