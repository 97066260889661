import { useState, useEffect } from 'react'
import { JsonApiError } from '../../utils/errors'
import { useAuthManager } from './useAuthManager'

export function useValidateCode(code: string, email: string, expiryDate: string | null) {
  const { login } = useAuthManager()
  const [isValidCode, setValidCode] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    function validateCode() {
      const dateIsExpired = !expiryDate || new Date() >= new Date(expiryDate)
      if (dateIsExpired) {
        setValidCode(false)
        setIsLoading(false)
        return
      }
      login(
        { email: email ?? '', password: code ?? '' },
        {
          onSuccess: () => {
            setValidCode(true)
            setIsLoading(false)
          },
          onError: err => {
            if (
              err instanceof JsonApiError &&
              err.status === 403 &&
              err.code === 'new_password_required'
            ) {
              setValidCode(true)
              setIsLoading(false)
            } else {
              setValidCode(false)
              setIsLoading(false)
            }
          },
        },
      )
    }
    validateCode()
  }, [email, code, expiryDate, login])
  return { isValidCode, isLoading }
}
