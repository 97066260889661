import { ApiStudentRequest, request } from '../utils/request'
import { useAuthQuery } from './auth'
import { GetProgramIntakeResponse as GetProgramIntakeResponseBE } from '@backend/students-api/routes/intakes/{id}/GET/types'

interface GetProgramIntakeProps {
  id?: string
}

export type GetProgramIntakeResponse = GetProgramIntakeResponseBE

export function useGetProgramIntake({ id }: GetProgramIntakeProps) {
  const { isLoading, data } = useAuthQuery(
    ['program-intake', id],
    async () => {
      return await request<GetProgramIntakeResponse>(
        new ApiStudentRequest(`/intakes/${id}`, {
          method: 'GET',
        }),
        {
          isExpectedResponse: (res): res is GetProgramIntakeResponse => res,
        },
      )
    },
    {
      enabled: !!id,
    },
  )

  return {
    isLoadingProgram: isLoading,
    intake: data?.data,
  }
}
