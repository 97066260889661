import { Button, createForm, Flex, Heading, TextInput, useToasts } from '@applyboard/crystal-ui'
import { useParams, useSearchParams } from 'react-router-dom'
import { useSignUpConfirmation } from '../../hooks'
import { isValidPassword } from '../../utils/fieldValidations'

type SignUpConfirmationFormFields = {
  newPassword: string
  confirmNewPassword: string
}

const { Form, Field, useFieldValues } = createForm<SignUpConfirmationFormFields>()

type SignUpConfirmationFormProps = {
  onSuccess: (password: string) => void
}

export function SignUpConfirmationForm(props: SignUpConfirmationFormProps) {
  const toast = useToasts()
  const { username } = useParams()
  const [searchParams] = useSearchParams()
  const { isConfirmingSignUp, signUpConfirmation } = useSignUpConfirmation()

  return (
    <Flex gap={10} direction="column">
      <Heading level={1} variant="headlineM" align="center">
        Create password
      </Heading>
      <Form
        defaultValues={{
          newPassword: '',
          confirmNewPassword: '',
        }}
        onSubmit={({ newPassword }) => {
          signUpConfirmation(
            {
              username: username ?? '',
              newPassword,
              temporaryPassword: searchParams?.get('code') ?? '',
            },
            {
              onSuccess: () => props.onSuccess(newPassword),
              onError: err => {
                if (err instanceof Error) {
                  toast.negative(new Error(err.message))
                }
              },
            },
          )
        }}
      >
        <Flex gap={10} direction="column">
          <Field
            as={TextInput}
            label="Password"
            name="newPassword"
            type="password"
            validate={password => {
              if (!isValidPassword(password)) {
                return 'A minimum 8 characters password contains a combination of uppercase and lowercase letters, numbers and symbols.'
              }

              return true
            }}
          />
          <Field
            as={TextInput}
            label="Confirm password"
            name="confirmNewPassword"
            type="password"
            validate={(confirmNewPassword, { values }) => {
              if (
                isValidPassword(values.newPassword) &&
                confirmNewPassword !== values.newPassword
              ) {
                return 'Password confirmation does not match.'
              }

              return true
            }}
          />
          <SubmitButton loading={isConfirmingSignUp} />
        </Flex>
      </Form>
    </Flex>
  )
}

type SubmitButtonLoading = {
  loading: boolean
}

function SubmitButton(props: SubmitButtonLoading) {
  const { newPassword, confirmNewPassword } = useFieldValues(['newPassword', 'confirmNewPassword'])
  return (
    <Button
      intent="primary"
      width="fill"
      type="submit"
      disabled={!isValidPassword(newPassword) || newPassword !== confirmNewPassword}
      loading={props.loading}
    >
      Set password
    </Button>
  )
}
