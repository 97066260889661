import { Flex, Heading } from '@applyboard/crystal-ui'

import { ApplicationDetailCardTitleIcon } from './ApplicationDetailCardTitleIcon'
import { DecisionDocumentTags } from '../../../../../utils/enums'

type ApplicationDetailCardTitleProps = Readonly<{
  title: string
  documentTag: DecisionDocumentTags
}>

export function ApplicationDetailCardTitle(props: ApplicationDetailCardTitleProps) {
  return (
    <Flex gap={3} align="center">
      <ApplicationDetailCardTitleIcon decisionDocumentTag={props.documentTag} />
      <Heading variant="titleS" level={3}>
        {props.title}
      </Heading>
    </Flex>
  )
}
