import { Avatar } from '@applyboard/crystal-ui'

import { getIcon } from './utils'
import { DecisionDocumentTags } from '../../../../../utils/enums'

type ApplicationDetailCardTitleIconProps = Readonly<{
  decisionDocumentTag: DecisionDocumentTags
}>

export function ApplicationDetailCardTitleIcon(props: ApplicationDetailCardTitleIconProps) {
  const { intent, icon } = getIcon(props.decisionDocumentTag)
  return <Avatar intent={intent} variant="highlighted" icon={icon} />
}
