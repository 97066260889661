import { useAuthQuery } from './auth'
import { ApiStudentRequest, request } from '../utils/request'
import { ProfileResource } from '@backend/resources/profile'

type GetProfileProps = {
  id?: string
}

type RawProfilesResponse = {
  data: ProfileResource
}

export function useGetProfile(props: GetProfileProps) {
  const { isLoading, data, isFetching } = useAuthQuery(
    ['profile'],
    async () => {
      return await request<RawProfilesResponse>(
        new ApiStudentRequest(`/profiles/${props.id}`, {
          method: 'GET',
        }),
        {
          isExpectedResponse: (res): res is RawProfilesResponse => res,
        },
      )
    },
    {
      enabled: !!props.id,
      refetchOnWindowFocus: false,
    },
  )

  return {
    isLoadingProfile: isLoading,
    isFetchingProfile: isFetching,
    profile: data?.data || null,
  }
}
