import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

const PROGRAM_FILTERS_KEY = 'students.ams.programFilters'
type Filters = {
  q: string
  campuses: string[]
  startDates: string[]
  statuses: string[]
  pageNumber: string
}

export function useProgramFilterSearchParams() {
  const [searchParams, setSearchParams] = useSearchParams()
  const filters = getFilters()

  useEffect(() => {
    saveFilters(filters)
    // eslint-disable-next-line
  }, [])

  function getFilters() {
    if (
      searchParams.get('q') === null &&
      searchParams.get('campuses') === null &&
      searchParams.get('startDates') === null &&
      searchParams.get('statuses') === null
    ) {
      const storedFilters = window.localStorage.getItem(PROGRAM_FILTERS_KEY)
      if (storedFilters !== null) {
        try {
          const storedFilterObj = JSON.parse(storedFilters)
          return storedFilterObj
        } catch (e) {
          console.error('Error parsing stored filters', e)
        }
      }
    }

    const paramsFilters = {
      q: searchParams.get('q') || '',
      campuses: searchParams.getAll('campuses'),
      startDates: searchParams.getAll('startDates'),
      statuses: searchParams.getAll('statuses'),
      pageNumber: searchParams.get('pageNumber') || '1',
    }

    return paramsFilters
  }

  function saveFilters(saveFilters: Filters) {
    const updateSearchParams = (searchParams: URLSearchParams, filters: Filters) => {
      for (const [key, value] of Object.entries(filters)) {
        if (Array.isArray(value)) {
          searchParams.delete(key)
          value.forEach(v => searchParams.append(key, v))
        } else {
          searchParams.set(key, value)
        }
      }
      return searchParams
    }

    setSearchParams(searchParams => {
      const updatedSearchParams = updateSearchParams(searchParams, saveFilters)
      return updatedSearchParams
    })
    window.localStorage.setItem(PROGRAM_FILTERS_KEY, JSON.stringify(saveFilters))
  }

  function updateFilters(newFilters: Partial<Filters>) {
    saveFilters({
      ...getFilters(),
      ...newFilters,
    })
  }

  function clearFilters() {
    saveFilters({
      q: '',
      campuses: [],
      startDates: [],
      statuses: [],
      pageNumber: '1',
    })
  }

  return {
    filters,
    updateFilters,
    clearFilters,
  }
}
