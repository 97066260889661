import { AuthStorage } from '../auth'
import { BaseRequest } from './BaseRequest'
import { getBaseUrl } from './getBaseUrl'

export class ApiStudentRequest extends BaseRequest {
  constructor(
    endpoint: string,
    options: {
      body?: object
      headers?: Record<string, string>
      method?: 'GET' | 'POST' | 'PATCH' | 'DELETE' | 'PUT'
    } = {},
  ) {
    const baseUrl = getBaseUrl()
    // TODO: Explicitly pass /api as endpoint
    const url: string = `${baseUrl}/api${endpoint}`
    options.headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthStorage.getAccessToken()}`,
      ...options.headers,
    }
    super(url, options)
  }
}
