import { COUNTRY_NAMES } from 'schools-domain-backend-utils/dist/common-types/country'

export function useGetDestinationCountry() {
  const destinationCountry = 'CA' //Mock data for demo
  const destinationCountryName = COUNTRY_NAMES[destinationCountry]

  return {
    destinationCountry,
    destinationCountryName,
  }
}
