import { Avatar, Button, Flex, Text } from '@applyboard/crystal-ui'
import { MenuOutlineIcon } from '@applyboard/ui-icons'
import styled from '@emotion/styled'
import { RawSchoolResponse, useGetProfile, useGetSchool, useLoggedInUser } from '../../hooks'
import { up } from '../../utils'

type HeaderProps = {
  desktopSidebarChange: () => void
  mobileSidebarChange: () => void
}

export function Header(props: HeaderProps) {
  const { username } = useLoggedInUser()
  const { isLoadingProfile, profile } = useGetProfile({ id: username })
  const { isLoadingSchool, school } = useGetSchool()

  return (
    <HeaderWrapper>
      <Flex justify="between" align="center" p={2} gap={2}>
        <Flex align="center" grow={{ xs: 1, sm: 0 }} gap={4}>
          <Flex hideBelow="sm">
            <Button
              onClick={props.desktopSidebarChange}
              aria-label="Main Menu"
              leadIcon={MenuOutlineIcon}
              intent="primary"
            />
          </Flex>
          {!isLoadingSchool && school ? <SchoolLogo school={school} /> : null}
        </Flex>
        <Flex hideAbove="sm">
          <Button
            onClick={props.mobileSidebarChange}
            aria-label="Main Menu"
            leadIcon={MenuOutlineIcon}
            intent="primary"
          />
        </Flex>
        <Flex hideBelow="sm">
          {!isLoadingProfile ? (
            <Avatar
              alt={
                profile ? `${profile.attributes.givenName} ${profile.attributes.familyName}` : ''
              }
              size="sm"
              initials={
                !profile || !profile.attributes.givenName
                  ? 'ME'
                  : `${profile?.attributes.givenName?.[0] ?? ''}${
                      profile?.attributes.familyName?.[0] ?? ''
                    }`.toUpperCase()
              }
            />
          ) : null}
        </Flex>
      </Flex>
    </HeaderWrapper>
  )
}

const HeaderWrapper = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.actionPrimary,
  zIndex: 1720,
  "button[aria-label='Main Menu']": {
    outlineColor: theme.colors.bgRegular,
  },
  [up('sm')]: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
  },
}))

type SchoolLogoProps = {
  school: RawSchoolResponse['data']
}

function SchoolLogo(props: SchoolLogoProps) {
  if (!props.school.attributes.logo) {
    return (
      <Text contrast="inverted" variant="bodyL">
        {props.school.attributes.name}
      </Text>
    )
  }

  return <img alt={props.school.attributes.name} src={props.school.attributes.logo} />
}
