import { useMutation } from '@tanstack/react-query'
import { AuthStudentRequest, request } from '../utils'

interface ForgotPasswordConfirmParams {
  username: string
  confirmationCode: string
  newPassword: string
}

export function useForgotPasswordConfirm() {
  const { isPending, mutate } = useMutation({
    mutationFn: async (params: ForgotPasswordConfirmParams): Promise<void> => {
      await request(
        new AuthStudentRequest(`/pre/users/${params.username}/password/reset/confirm`, {
          method: 'POST',
          body: {
            confirmationCode: params.confirmationCode,
            newPassword: params.newPassword,
          },
        }),
        {
          isExpectedResponse: (res): res is any => true,
        },
      )
    },
  })

  return {
    forgotPasswordConfirm: mutate,
    isLoadingForgotPasswordConfirm: isPending,
  }
}
