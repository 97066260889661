import { useAuthQuery } from './auth'
import { ApiStudentRequest, request } from '../utils'
import { GetCampusesResponse } from '@backend/students-api/routes/campuses/GET/types'

export function useGetCampuses() {
  const { isLoading, isFetching, data } = useAuthQuery(
    ['campuses'],
    async () => {
      return await request<GetCampusesResponse>(
        new ApiStudentRequest('/campuses', {
          method: 'GET',
        }),
        {
          isExpectedResponse: (res): res is GetCampusesResponse => res,
        },
      )
    },
    {
      refetchOnWindowFocus: false,
    },
  )

  return {
    isLoadingCampuses: isLoading,
    isFetchingCampuses: isFetching,
    campuses: data?.data || [],
  }
}
