import styled from '@emotion/styled'
import { Button, Flex, Stepper, ButtonProps } from '@applyboard/crystal-ui'
import { TimelineItemDot } from './TimelineItemDot'
import { useQueryClient } from '@tanstack/react-query'
import { StudentApplication } from '../types'
import { useGetTimelineItems } from '../../../hooks/useGetTimelineItems'

type ApplicationTimelineProps = {
  selectedTab: number
  setSelectedTab: (tab: number) => void
  application: StudentApplication
}

export function ApplicationTimeline(props: ApplicationTimelineProps) {
  const queryClient = useQueryClient()
  const timelineItems = useGetTimelineItems(props.application)

  function getIntent(index: number, isCurrentCompleted: boolean): ButtonProps['intent'] {
    if (index === props.selectedTab - 1) return 'primary'
    if (isCurrentCompleted) return 'positive'
    return 'secondary'
  }

  return (
    <>
      <Flex hideAbove="md" align="center" pt={3}>
        <Stepper
          value={props.selectedTab - 1}
          aria-label="Application Timeline"
          orientation="circular"
        >
          {timelineItems.map((timelineItem, index) => {
            const intent = getIntent(index, timelineItem.completed)
            return (
              <Stepper.Step key={timelineItem.key} intent={intent}>
                <Stepper.Step.Title variant="titleS">{timelineItem.label}</Stepper.Step.Title>
              </Stepper.Step>
            )
          })}
        </Stepper>
      </Flex>
      <Flex hideBelow="md">
        <TimelineWrapper>
          {/* split desktop & mobile timeline, extract logic for selected or not */}
          <TimelineV1 aria-label="Application Timeline">
            {timelineItems.map((timelineItem, index) => {
              const isPreviousCompleted = index > 0 ? timelineItems[index - 1].completed : true
              const active =
                index + 1 !== props.selectedTab && (timelineItem.completed || isPreviousCompleted)

              return (
                <TimelineItem
                  key={timelineItem.key}
                  aria-label={`${timelineItem.label} ${
                    timelineItem.completed ? 'is complete' : 'is incomplete'
                  }`}
                >
                  <TimelineItemDotWrapper>
                    {index !== 0 && <TopLine />}
                    <TimelineItemDot
                      isSelected={props.selectedTab === index + 1}
                      isCompleted={timelineItem.completed}
                    />
                    {index < timelineItems.length - 1 && <BottomLine />}
                  </TimelineItemDotWrapper>
                  <Button
                    disabled={!active}
                    emphasis="transparent"
                    onClick={() => {
                      if (active) {
                        queryClient.invalidateQueries({
                          queryKey: ['applications', props.application?.id],
                        })
                        props.setSelectedTab(index + 1)
                      }
                    }}
                  >
                    {timelineItem.label}
                  </Button>
                </TimelineItem>
              )
            })}
          </TimelineV1>
        </TimelineWrapper>
      </Flex>
    </>
  )
}

const borderWidth = '1px'
const iconHeightPx = '20px'
const columnGapPx = '8px'
const rowGapPx = '24px'

const TimelineWrapper = styled.div({
  height: 'fit-content',
})

const TimelineV1 = styled.ol({
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  columnGap: columnGapPx,
  rowGap: rowGapPx,
  marginBlock: 0,
  paddingInline: 0,
})

const TimelineItem = styled.li({
  display: 'contents',
  textWrap: 'nowrap',
  'button > div': {
    justifyContent: 'flex-start',
  },
})

const TimelineItemDotWrapper = styled.div({
  alignItems: 'center',
  alignSelf: 'center',
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  position: 'relative',
})

const TopLine = styled.div(({ theme }) => {
  return {
    border: `${borderWidth} solid ${theme.colors.borderInactive}`,
    bottom: `calc(50% + (${iconHeightPx} / 2) + ${borderWidth})`,
    height: `calc(50% - (${iconHeightPx} / 2) + (${rowGapPx} / 2) - ${borderWidth})`,
    left: `calc(50% - ${borderWidth})`,
    position: 'absolute',
    width: 0,
  }
})

const BottomLine = styled.div(({ theme }) => {
  return {
    border: `1px solid ${theme.colors.borderInactive}`,
    height: `calc(50% - (${iconHeightPx} / 2) + (${rowGapPx} / 2) - ${borderWidth})`,
    left: `calc(50% - ${borderWidth})`,
    position: 'absolute',
    top: `calc(50% + (${iconHeightPx} / 2) + ${borderWidth})`,
    width: 0,
  }
})
