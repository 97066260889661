import { useEffect, useState } from 'react'

import { Flex, Heading, Notice, Tabs } from '@applyboard/crystal-ui'
import { BulletFillIcon } from '@applyboard/ui-icons'
import styled from '@emotion/styled'
import { find } from 'lodash'
import { Navigate, useParams } from 'react-router-dom'
import {
  ApplicationForms,
  ApplicationRequests,
  ApplicationTimeline,
} from '../../components/Application'
import { DesktopHeader } from '../../components/Application/ApplicationHeader/DesktopHeader'
import ApplicationStateTag from '../../components/Application/ApplicationStateTag/ApplicationStateTag'
import { Loading } from '../../components/Loading'
import { NavBars } from '../../components/NavBars'
import { ApplicationState, useGetApplication } from '../../hooks'
import { up } from '../../utils'
import { BackToApplication } from '../CreateApplicationPage/BackToApplication'
import { TopMenu } from './TopMenu'
import { ApplicationDetails } from '../../components/Application/ApplicationDetails/ApplicationDetails'
import { DecisionType } from '../../utils/enums'
// import { DecisionTypeEnum } from '../../utils/enums'

enum TabLabel {
  Submitted = 1,
  Details,
  Requests,
}

export function ViewApplicationPage() {
  const { id } = useParams()
  const { isLoadingApplication, application, school } = useGetApplication({ id })
  const [tab, setTab] = useState<string | null>(`${TabLabel.Submitted}`)
  const [currentStep, setCurrentStep] = useState(1)
  const selectedTab = currentStep

  useEffect(() => {
    if (
      application?.attributes?.applicationState === ApplicationState.INITIAL_OFFER ||
      !!Object.keys(application?.attributes?.studentDecisions || {}).length
    ) {
      setTab(`${TabLabel.Details}`)
    } else if (application) {
      setTab(`${TabLabel.Submitted}`)
    }
  }, [application])

  const showPreOfferBanner =
    application?.attributes?.applicationState === ApplicationState.INITIAL_OFFER ||
    (application?.attributes?.studentDecisions &&
      DecisionType.PRE_OFFER in application.attributes.studentDecisions)
  if (isLoadingApplication) {
    return (
      <NavBars>
        <Loading />
      </NavBars>
    )
  }

  if (!application) {
    return <Navigate to="/not-found" />
  }

  if (application.attributes?.applicationState === ApplicationState.DRAFT) {
    return <Navigate to={`/applications/new?applicationId=${application.id}`} />
  }

  return (
    <NavBars largePage>
      <BackToApplication
        label={`${application.attributes?.programSelected?.program?.name} Application`}
      />
      <Flex direction="column" gap={6}>
        <Flex wrap={false} align="center" justify="between" gap={4}>
          <Flex
            hideBelow={selectedTab === 7 ? 'sm' : undefined}
            align={{ xs: 'start', md: 'center' }}
            direction={{ xs: 'column', md: 'row' }}
            gap={4}
          >
            <Flex hideBelow="md">
              <img
                src="/application-illustration.svg"
                alt={`Application for ${application.attributes?.programSelected?.program?.name}`}
              />
            </Flex>
            <Heading level={1} variant="headlineL">
              Application for {application.attributes?.programSelected?.program?.name}
            </Heading>
            <ApplicationStateTag
              state={application.attributes?.applicationState}
              hasOpenDocumentRequests={application.attributes?.hasOpenDocumentRequests}
            />
          </Flex>
          <TopMenu applicationId={application.id} />
        </Flex>
        {application.attributes?.hasOpenDocumentRequests ? (
          <Flex grow={1}>
            <Notice intent="warning">
              Additional documents have been requested to complete the assessment of your
              application.
            </Notice>
          </Flex>
        ) : null}
        {showPreOfferBanner ? (
          <Flex>
            <Notice intent="positive">Congratulations! You have received a pre-offer</Notice>
          </Flex>
        ) : null}
        <Flex gap={{ xs: 6, md: 12 }} direction={{ xs: 'column', md: 'row' }}>
          <Flex direction="column" gap={4} basis={{ xs: '100%', md: 278 }}>
            <DesktopHeader application={application} showProgramName={false} />
            {tab === `${TabLabel.Submitted}` ? (
              <Flex hideBelow="md">
                <ApplicationTimeline
                  selectedTab={selectedTab}
                  setSelectedTab={tab => setCurrentStep(tab)}
                  application={application}
                />
              </Flex>
            ) : null}
          </Flex>
          <TabWrapper>
            <Tabs
              onChange={value => setTab(`${value}`)}
              value={`${tab}`}
              aria-label="Application Tabs"
              orientation="horizontal"
            >
              <Tabs.Item value={`${TabLabel.Submitted}`} label={TabLabel[TabLabel.Submitted]}>
                <Flex hideAbove="md" pb={4}>
                  <ApplicationTimeline
                    selectedTab={selectedTab}
                    setSelectedTab={tab => setCurrentStep(tab)}
                    application={application}
                  />
                </Flex>
                <ApplicationForms
                  disabled
                  selectedTab={selectedTab}
                  application={application}
                  onSuccess={() => {
                    if (selectedTab <= 6) {
                      setCurrentStep(currentStep + 1)
                    }
                  }}
                />
              </Tabs.Item>
              {application.attributes?.applicationState === ApplicationState.INITIAL_OFFER ? (
                <Tabs.Item value={`${TabLabel.Details}`} label={TabLabel[TabLabel.Details]}>
                  <ApplicationDetails
                    application={application}
                    school={school}
                    onOfferConditionsClick={() => setTab(`${TabLabel.Requests}`)}
                  />
                </Tabs.Item>
              ) : null}
              <Tabs.Item
                value={`${TabLabel.Requests}`}
                label={TabLabel[TabLabel.Requests]}
                icon={
                  find(
                    application.attributes?.requestedDocuments,
                    r => r?.requestStatus === 'PENDING',
                  )
                    ? () => <BulletFillIcon intent="negative" />
                    : undefined
                }
              >
                <ApplicationRequests application={application} />
              </Tabs.Item>
            </Tabs>
          </TabWrapper>
        </Flex>
      </Flex>
    </NavBars>
  )
}

const TabWrapper = styled.div({
  width: '100%',
  [up('md')]: {
    width: 'calc(100% - 326px)',
  },
})
