import { ApiStudentRequest, request } from '../utils/request'
import { useQuery } from '@tanstack/react-query'

export type RawSchoolResponse = {
  data: {
    id: string
    type: 'school'
    attributes: {
      logo: string
      name: string
      url: string
    }
  }
}

export function useGetSchool() {
  const { isLoading, data } = useQuery({
    queryKey: ['schools'],
    queryFn: async () => {
      return await request<RawSchoolResponse>(
        new ApiStudentRequest(`/pre/school`, {
          method: 'GET',
        }),
        {
          isExpectedResponse: (res): res is RawSchoolResponse => res,
        },
      )
    },
    enabled: true,
    refetchOnWindowFocus: false,
  })

  return {
    isLoadingSchool: isLoading,
    school: data?.data || null,
  }
}
