import { format } from 'date-fns'
import { Tag } from '@applyboard/crystal-ui'

import { getIntent } from './utils'
import { DecisionDocumentTags, DocumentTags } from '../../../../../utils/enums'

type ApplicationDetailCardIssuedProps = Readonly<{
  issuedOn: Date | undefined
  decisionDocumentTag: DecisionDocumentTags
}>

export function ApplicationDetailCardIssued(props: ApplicationDetailCardIssuedProps) {
  const text =
    props.decisionDocumentTag === DocumentTags.DECLINED_LETTER ? 'Declined on' : 'Issued on'
  if (props.issuedOn === undefined) {
    return null
  }
  return (
    <Tag intent={getIntent(props.decisionDocumentTag)}>
      {`${text} ${format(props.issuedOn, 'MMM dd, yyy')}`}
    </Tag>
  )
}
