import React, { useEffect } from 'react'

import { Button, createForm, Divider, Flex, Select, Text, useToasts } from '@applyboard/crystal-ui'
import { DeleteOutlineIcon, PlusOutlineIcon } from '@applyboard/ui-icons'
import type { FileData, FileMetaData } from 'applications-types-lib'

import { EmotionJSX } from '@emotion/react/types/jsx-namespace'
import { nanoid } from 'nanoid'

import { RawApplicationResponse, useUpdateApplication } from '../../../hooks'
import { useApplicationFormContext } from './ApplicationForm'
import { AdditionalDocumentTags, DocumentTags } from '../../../utils/enums'
import { FileUploadField } from './FileUploadField'
import { additionalDocumentTypeOptions, GenericError } from '../../../utils'
import { getFilesOfType } from './utils'
import { StudentApplication } from '../types'

type AdditionalDocument = {
  documents: Array<{
    id: string
    file: File
  }>
  documentType: string
  id: string
}

type DocumentTypeOptions = Record<AdditionalDocumentTags, { selected: boolean; label: string }>

type AdditionalDocumentsFormFields = {
  additionalDocuments: AdditionalDocument[]
  documentTypeOptions: DocumentTypeOptions
}

const { Form, Field, useFieldArray, useFieldValues, useSetFieldValues } =
  createForm<AdditionalDocumentsFormFields>()

type AdditionalDocumentsTabProps = {
  disabled?: boolean
  selectedTab: number
  application: StudentApplication
  onSuccess: (response?: RawApplicationResponse) => void
  onError: (err: GenericError) => void
  submitApplication?: () => void
  updateApplication: ReturnType<typeof useUpdateApplication>['updateApplication']
  formId: string
}

export function AdditionalDocumentsTab(props: AdditionalDocumentsTabProps) {
  const { resetFiles, pendingFileUploadState } = useApplicationFormContext()

  useEffect(() => {
    resetFiles(
      getFilesOfType(
        Object.keys(additionalDocumentTypeOptions) as DocumentTags[],
        props.application?.attributes?.files as FileData,
      ),
    )
  }, [props.application?.attributes?.files, resetFiles])
  const toast = useToasts()

  return (
    <Form
      id={props.formId}
      defaultValues={setDefaultAdditionalDocuments(
        props.application?.attributes?.files as FileData,
      )}
      onSubmit={_data => {
        if (props.disabled) {
          props.onSuccess()
        } else {
          props.updateApplication(
            {
              files: pendingFileUploadState,
            },
            {
              onSuccess: response => {
                resetFiles(
                  getFilesOfType(
                    Object.keys(additionalDocumentTypeOptions) as DocumentTags[],
                    response.data?.attributes?.files as FileData,
                  ),
                )
                props.onSuccess(response)
                toast.positive('Your application has been saved successfully.')
              },
              onError: props.onError,
            },
          )
        }
      }}
    >
      <Documents disabled={props.disabled} application={props.application} />
    </Form>
  )
}

type DocumentsProps = {
  disabled?: boolean
  application: StudentApplication
}

function Documents(props: DocumentsProps) {
  const toast = useToasts()
  const { addPendingDelete, getObservableFiles } = useApplicationFormContext()
  const { append, fields, remove } = useFieldArray({ name: 'additionalDocuments' })
  const { additionalDocuments, documentTypeOptions } = useFieldValues([
    'additionalDocuments',
    'documentTypeOptions',
  ])
  const setFieldValues = useSetFieldValues()

  return (
    <Flex direction="column" gap={6} p={2}>
      <Text variant="bodyM">Please provide any additional documents required by the program.</Text>
      <Flex direction="column" gap={4}>
        {fields.map((field, index) => {
          if (index >= additionalDocuments.length) return null

          const currentDocumentType = additionalDocuments[index].documentType
          const hasObservableFiles = !!currentDocumentType
            ? !!Object.keys(
                getObservableFiles({
                  fileType: currentDocumentType,
                  sectionReference: 'other',
                }),
              ).length
            : false

          return (
            <React.Fragment key={field.id}>
              <Flex>
                <Flex.Item basis={{ xs: '100%', sm: '50%' }}>
                  <Field
                    as={Select}
                    disabled={hasObservableFiles || !!props.disabled}
                    label="Document Type"
                    name={`additionalDocuments.${index}.documentType`}
                    appearance="styled"
                    onChange={value => {
                      const oldDocumentType: AdditionalDocumentTags =
                        currentDocumentType as AdditionalDocumentTags
                      const newDocumentType: AdditionalDocumentTags =
                        value as AdditionalDocumentTags
                      if (documentTypeOptions[newDocumentType]) {
                        documentTypeOptions[newDocumentType].selected = true
                      }
                      if (documentTypeOptions[oldDocumentType]) {
                        documentTypeOptions[oldDocumentType].selected = false
                      }

                      setFieldValues({ documentTypeOptions })
                    }}
                  >
                    {Object.entries(documentTypeOptions).reduce(
                      (
                        options: EmotionJSX.Element[],
                        [documentValue, { selected, label }]: any,
                      ) => {
                        if (selected && documentValue !== currentDocumentType) return options
                        options.push(
                          <Select.Option
                            key={crypto.randomUUID()}
                            label={label}
                            value={documentValue}
                          />,
                        )
                        return options
                      },
                      [],
                    )}
                  </Field>
                </Flex.Item>
              </Flex>
              {!!currentDocumentType ? (
                <Field
                  as={FileUploadField}
                  allowedFileTypes={[
                    'pdf',
                    'png',
                    'jpeg',
                    'jpg',
                    'doc',
                    'docx',
                    'txt',
                    'xls',
                    'xlsx',
                    'mp4',
                    'avi',
                    'mov',
                    'flv',
                    'wmv',
                  ]}
                  application={props.application}
                  disabled={props.disabled}
                  fileType={DocumentTags[currentDocumentType as AdditionalDocumentTags]}
                  name={`additionalDocuments.${index}.documents`}
                  section={`other`}
                  showHistory={false}
                />
              ) : null}
              {!props.disabled && Object.keys(additionalDocuments).length > 1 ? (
                <Flex>
                  <Button
                    emphasis="highlighted"
                    intent="negative"
                    width="fill"
                    leadIcon={DeleteOutlineIcon}
                    onClick={() => {
                      const documentType = additionalDocuments[index]
                        .documentType as AdditionalDocumentTags
                      if (hasObservableFiles) {
                        Object.keys(
                          getObservableFiles({ fileType: documentType, sectionReference: 'other' }),
                        ).forEach(id => {
                          addPendingDelete(id)
                        })
                      }

                      if (!!documentType) {
                        ;(documentTypeOptions[documentType] as { selected: boolean }).selected =
                          false
                      }
                      remove(index)
                      setFieldValues({
                        documentTypeOptions: documentTypeOptions,
                      })
                    }}
                  >
                    {`Remove`}
                  </Button>
                </Flex>
              ) : null}
              <Divider />
            </React.Fragment>
          )
        })}
      </Flex>
      {!props.disabled ? (
        <Flex>
          <Button
            emphasis="highlighted"
            intent="primary"
            width="fill"
            leadIcon={PlusOutlineIcon}
            disabled={fields.length >= Object.keys(documentTypeOptions).length}
            onClick={() => {
              if (fields.length === Object.keys(documentTypeOptions).length - 1) {
                toast.warning(
                  'The maximum number of documents has been reached. Please remove a document before adding.',
                )
              }

              append(newAdditionalDocument())
            }}
          >
            Add another supporting document
          </Button>
        </Flex>
      ) : null}
    </Flex>
  )
}

function newAdditionalDocument(): AdditionalDocument {
  return {
    documents: [],
    documentType: '',
    id: nanoid(),
  }
}

function setDefaultAdditionalDocuments(files?: FileData): AdditionalDocumentsFormFields {
  const newDocumentTypeOptions = Object.entries(
    additionalDocumentTypeOptions,
  ).reduce<DocumentTypeOptions>((newDocumentTypeOptions: any, defaultDocumentTypeOptionEntry) => {
    const [documentType, { label }] = defaultDocumentTypeOptionEntry as [
      AdditionalDocumentTags,
      { label: string },
    ]
    newDocumentTypeOptions[documentType] = { selected: false, label: label }
    return newDocumentTypeOptions
  }, {} as DocumentTypeOptions)

  const formObject: AdditionalDocumentsFormFields = {
    additionalDocuments: [],
    documentTypeOptions: newDocumentTypeOptions,
  }

  let hasUploadedFiles = false

  Object.values(files || []).forEach((file: FileMetaData | null) => {
    if (!file || !formObject.documentTypeOptions.hasOwnProperty(file.type)) return

    const fileType: AdditionalDocumentTags = file.type as unknown as AdditionalDocumentTags
    hasUploadedFiles = true

    if (formObject.documentTypeOptions[fileType].selected) return

    formObject.documentTypeOptions[fileType].selected = true

    formObject.additionalDocuments.push({ id: nanoid(), documents: [], documentType: fileType })
  })

  if (!hasUploadedFiles) {
    formObject.additionalDocuments = [newAdditionalDocument()]
  }

  return formObject
}
