import {
  DatePicker,
  Flex,
  Heading,
  Select,
  Text,
  TextInput,
  createForm,
} from '@applyboard/crystal-ui'
import { FileData } from 'applications-types-lib'
import { useEffect, useState } from 'react'
import { RawApplicationResponse, useUpdateApplication } from '../../../hooks'
import { convertTimelessDateStrToLocalDate } from '../../../utils/convertTimelessDateStrToLocalDate'

import { find, get } from 'lodash'
import { nanoid } from 'nanoid'
import { GenericError } from '../../../utils'
import { DestinationCountryStatus, DocumentTags } from '../../../utils/enums'
import { Asterisk } from '../../Asterisk'
import { StudentApplication } from '../types'
import { useApplicationFormContext } from './ApplicationForm'
import { FileUploadField } from './FileUploadField'
import { getFilesOfType } from './utils'
import { useGetDestinationCountry } from '../../../hooks/useGetDestinationCountry'

type StatusAndCitizenshipFormFields = {
  passportNumber: string
  passportExpiryDate: string
  passportFiles: Array<{
    id: string
    file: File
  }>
  immigrationStatus: string
  immigrationFiles: Array<{
    id: string
    file: File
  }>
}

const UPLOAD_LIMIT = 1

const { Form, Field, useFieldValues, useSetFieldValues } =
  createForm<StatusAndCitizenshipFormFields>()

type StatusAndCitizenshipTabProps = {
  disabled?: boolean
  application: StudentApplication
  onSuccess: (response?: RawApplicationResponse) => void
  onError: (err: GenericError) => void
  updateApplication: ReturnType<typeof useUpdateApplication>['updateApplication']
  formId: string
}

export function StatusAndCitizenshipTab(props: StatusAndCitizenshipTabProps) {
  const { resetFiles, pendingFileUploadState } = useApplicationFormContext()
  const { destinationCountryName } = useGetDestinationCountry()

  useEffect(() => {
    resetFiles(
      getFilesOfType(
        [DocumentTags.PASSPORT, DocumentTags.DESTINATION_COUNTRY_STATUS],
        props.application?.attributes?.files as FileData,
      ),
    )
  }, [props.application?.attributes?.files, resetFiles])

  const activePassport = find(
    props.application?.attributes?.files,
    itm =>
      (itm?.type as unknown as DocumentTags | undefined) === DocumentTags.PASSPORT &&
      !!itm?.activeRecord,
  )
  const passportInfo = activePassport?.sectionReference
    ? get(
        props.application?.attributes?.statusAndCitizenship?.passports,
        activePassport?.sectionReference,
      )
    : undefined
  const [passportId] = useState(activePassport?.sectionReference || nanoid())

  return (
    <Form
      id={props.formId}
      defaultValues={{
        passportNumber: passportInfo?.passportNumber || '',
        passportExpiryDate: passportInfo?.passportExpiry
          ? convertTimelessDateStrToLocalDate(passportInfo.passportExpiry).toISOString()
          : '',
        passportFiles: [],
        immigrationStatus:
          props.application?.attributes?.statusAndCitizenship?.destinationCountryStatus || '',
        immigrationFiles: [],
      }}
      onSubmit={data => {
        if (props.disabled) {
          props.onSuccess()
        } else {
          props.updateApplication(
            {
              attributes: {
                statusAndCitizenship: {
                  destinationCountryStatus: data.immigrationStatus as DestinationCountryStatus,
                  passports: {
                    [passportId]: {
                      passportNumber: data.passportNumber,
                      passportExpiry: data.passportExpiryDate?.substring(0, 10),
                    },
                  },
                },
              },
              files: pendingFileUploadState,
            },
            {
              onSuccess: response => {
                resetFiles(
                  getFilesOfType(
                    [DocumentTags.PASSPORT, DocumentTags.DESTINATION_COUNTRY_STATUS],
                    response.data?.attributes?.files as FileData,
                  ),
                )
                props.onSuccess(response)
              },
              onError: props.onError,
            },
          )
        }
      }}
    >
      <StatusAndCitizenshipTabFields
        application={props.application}
        passportId={passportId}
        disabled={props.disabled}
        destinationCountryName={destinationCountryName}
      />
    </Form>
  )
}

type StatusAndCitizenshipTabFieldsProps = {
  application: StudentApplication
  destinationCountryName: string
  passportId: string
  disabled?: boolean
}

function StatusAndCitizenshipTabFields(props: StatusAndCitizenshipTabFieldsProps) {
  const { passportFiles, immigrationFiles, immigrationStatus } = useFieldValues([
    'passportFiles',
    'immigrationFiles',
    'immigrationStatus',
  ])

  const setFieldValues = useSetFieldValues()

  const [hasValidVisa, setValidVisa] = useState(
    immigrationStatus !== 'NO_STATUS' && !!immigrationStatus,
  )
  const { addPendingDelete, getObservableFiles } = useApplicationFormContext()

  const maxDate = new Date()
  const minDate = new Date()
  maxDate.setFullYear(maxDate.getFullYear() + 10)
  minDate.setFullYear(minDate.getFullYear() - 3)

  const immigrationStatusList = [
    {
      key: crypto.randomUUID(),
      value: DestinationCountryStatus.NO_STATUS,
      label: "I don't have this",
    },
    {
      key: crypto.randomUUID(),
      value: DestinationCountryStatus.STUDY_PERMIT,
      label: 'Study permit',
    },
    { key: crypto.randomUUID(), value: DestinationCountryStatus.WORK_PERMIT, label: 'Work permit' },
    {
      key: crypto.randomUUID(),
      value: DestinationCountryStatus.VISITOR,
      label: 'Visitor/Tourist visa',
    },
    {
      key: crypto.randomUUID(),
      value: DestinationCountryStatus.OTHER,
      label: 'Other',
    },
  ]

  return (
    <Flex gap={4} direction={{ xs: 'column', sm: 'row' }} wrap>
      <Flex basis="100%">
        <Heading variant="titleS" level={3}>
          Citizenship Information
        </Heading>
      </Flex>
      <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
        <Field
          as={TextInput}
          label="Passport number"
          name="passportNumber"
          disabled={props.disabled}
          required={!props.disabled ? 'Passport number is required' : false}
        />
      </Flex.Item>
      <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
        <Field
          as={DatePicker}
          label="Passport expiry date"
          name="passportExpiryDate"
          maxDate={maxDate.toISOString()}
          minDate={minDate.toISOString()}
          disabled={props.disabled}
          required={!props.disabled ? 'Passport expiry date is required' : false}
        />
      </Flex.Item>
      <Flex gap={4} direction="column" basis="100%">
        <Field
          as={FileUploadField}
          allowedFileTypes={['.jpg', '.pdf', '.png', '.jpeg']}
          application={props.application}
          disabled={props.disabled}
          fileLimit={UPLOAD_LIMIT}
          fileType={DocumentTags.PASSPORT}
          label={
            <Text variant={'bodyM'}>
              Add your passport document below, supported file formats: JPG, JPEG, PDF, PNG, max
              number of files: {UPLOAD_LIMIT} <Asterisk />
            </Text>
          }
          name={`passportFiles`}
          onRemove={(id: string) =>
            setFieldValues({ passportFiles: passportFiles.filter(file => file.id !== id) })
          }
          section={props.passportId}
          showHistory={!!props.disabled}
          validate={value => {
            const observableFiles = getObservableFiles({
              fileType: DocumentTags.PASSPORT,
              sectionReference: props.passportId,
            })

            if (Object.keys(observableFiles).length > UPLOAD_LIMIT) {
              return `This field has a file limit of ${UPLOAD_LIMIT}.`
            }

            if (!(Object.keys(observableFiles).length || value.length)) {
              return 'This field is required'
            }
            return true
          }}
        />
      </Flex>
      <Flex basis="100%" direction="column">
        <Heading variant="titleS" level={3}>
          {props.destinationCountryName || 'Destination Country'} Status
        </Heading>
        <Text variant="bodyS">
          Tell us below your current visa/immigration status in {props.destinationCountryName}.
        </Text>
      </Flex>
      <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
        <Field
          as={Select}
          label="Status"
          name="immigrationStatus"
          appearance="styled"
          disabled={props.disabled}
          required={!props.disabled ? 'Status is required' : false}
          onChange={v => {
            const fileIds = Object.keys(
              getObservableFiles({ fileType: DocumentTags.DESTINATION_COUNTRY_STATUS }),
            )
            if ((!v || v === 'NO_STATUS') && !!fileIds.length) {
              fileIds.forEach(id => addPendingDelete(id))
            }
            setValidVisa(!!v && v !== 'NO_STATUS')
          }}
        >
          {immigrationStatusList.map(status => (
            <Select.Option key={status.key} label={status.label} value={status.value} />
          ))}
        </Field>
      </Flex.Item>
      {hasValidVisa ? (
        <Flex gap={4} direction="column" basis="100%">
          <Field
            as={FileUploadField}
            allowedFileTypes={['.jpg', '.pdf', '.png', '.jpeg']}
            application={props.application}
            disabled={props.disabled || !hasValidVisa}
            fileType={DocumentTags.DESTINATION_COUNTRY_STATUS}
            label={
              <Text variant={'bodyM'}>
                Add your{' '}
                {immigrationStatusList.find(status => status.value === immigrationStatus)?.label}{' '}
                document(s) below, supported file formats: PDF, JPEG, PNG <Asterisk />
              </Text>
            }
            name={`immigrationFiles`}
            onRemove={(id: string) =>
              setFieldValues({ immigrationFiles: immigrationFiles.filter(file => file.id !== id) })
            }
            section={`destinationCountryStatus`}
            validate={value => {
              const observableFiles = getObservableFiles({
                fileType: DocumentTags.DESTINATION_COUNTRY_STATUS,
                sectionReference: 'destinationCountryStatus',
              })

              if (hasValidVisa && !(Object.keys(observableFiles).length || value.length)) {
                return 'This field is required'
              }
              return true
            }}
            showHistory={false}
          />
        </Flex>
      ) : null}
    </Flex>
  )
}
