import { Button, Flex } from '@applyboard/crystal-ui'
import { Ref } from 'react'

type FormSubmitButtonGroupProps = {
  cardNumber: number
  disabled?: boolean
  fullWidth?: boolean
  isLoading?: boolean
  submitButtonLabel?: string
  onSubmitApplication?: () => void
  onPreviousTabButton?: () => void
  formId: string
  focusRef?: Ref<HTMLButtonElement> | null
}

export function FormSubmitButtonGroup(props: FormSubmitButtonGroupProps) {
  if (props.disabled) {
    if (props.cardNumber < 6) {
      return (
        <Button
          intent="primary"
          type="submit"
          width={props.fullWidth ? 'fill' : undefined}
          loading={props.isLoading}
          form={props.formId}
        >
          Continue
        </Button>
      )
    }

    return null
  }

  if (props.onSubmitApplication) {
    return (
      <>
        <Button
          type="submit"
          intent="primary"
          emphasis="transparent"
          loading={props.isLoading}
          width={props.fullWidth ? 'fill' : undefined}
          form={props.formId}
          mr={8}
        >
          Save
        </Button>
        <Button
          disabled={props.isLoading}
          type="button"
          intent="primary"
          emphasis="outlined"
          onClick={props.onPreviousTabButton}
        >
          Previous
        </Button>
        <Button
          ref={props.focusRef}
          intent="primary"
          disabled={props.isLoading}
          onClick={props.onSubmitApplication}
          width={props.fullWidth ? 'fill' : undefined}
        >
          {props.submitButtonLabel ?? 'Save & Submit'}
        </Button>
      </>
    )
  }

  return (
    <Flex gap={3} align="center" justify="end" height="fill" direction="row">
      {props.cardNumber > 1 && (
        <Button
          disabled={props.isLoading}
          type="button"
          intent="primary"
          emphasis="outlined"
          onClick={props.onPreviousTabButton}
        >
          Previous
        </Button>
      )}
      <Button type="submit" intent="primary" loading={props.isLoading} form={props.formId}>
        Save & Continue
      </Button>
    </Flex>
  )
}
