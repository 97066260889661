import {
  ButtonLink,
  Card,
  DescriptionList,
  Divider,
  Flex,
  Heading,
  Text,
} from '@applyboard/crystal-ui'
import ApplicationStateTag from '../../components/Application/ApplicationStateTag/ApplicationStateTag'
import { RawListApplicationsResponse } from '../../hooks/useListApplications'
import { ApplicationState } from '../../hooks'
import { DeleteApplicationButton } from './DeleteApplicationButton'

type ApplicationsCardProps = {
  applications: RawListApplicationsResponse['data'] | null
  onDelete: (application: RawListApplicationsResponse['data'][number]) => void
}

export function ApplicationsCard(props: ApplicationsCardProps) {
  return (
    <Flex hideAbove="md" direction="column" gap={6} pt={6}>
      {props.applications?.map(application => (
        <Card key={application.id} emphasis="outlined">
          <Card.Header>
            <Text>{application.attributes?.humanReadableId}</Text>
            <Heading level={2}>
              {!application.attributes?.programSelected?.program?.name
                ? `No program selected`
                : application.attributes.programSelected.program.name}
            </Heading>
          </Card.Header>
          <Divider />
          <DescriptionList aria-label={`Application #${application.id}`} variant="free">
            <Flex direction="column" gap={4}>
              <Flex justify="between" gap={1}>
                <DescriptionList.Term>Intake Term</DescriptionList.Term>
                <DescriptionList.Details>
                  {application.attributes?.programSelected?.programIntakeTerm?.name || '--'}
                </DescriptionList.Details>
              </Flex>
              <Flex justify="between" gap={1}>
                <DescriptionList.Term>Status</DescriptionList.Term>
                <DescriptionList.Details>
                  <ApplicationStateTag
                    state={application.attributes?.applicationState}
                    hasOpenDocumentRequests={application.attributes?.hasOpenDocumentRequests}
                  />
                </DescriptionList.Details>
              </Flex>
            </Flex>
          </DescriptionList>
          <Divider />
          <Card.Footer>
            {application.attributes?.applicationState === ApplicationState.DRAFT ? (
              <Flex gap={4}>
                <ButtonLink
                  href={`/applications/new?applicationId=${application.id}`}
                  intent="primary"
                  emphasis="outlined"
                  width="fill"
                >
                  Finish Application
                </ButtonLink>

                <Flex>
                  <DeleteApplicationButton
                    application={application}
                    onSuccess={() => props.onDelete(application)}
                  />
                </Flex>
              </Flex>
            ) : (
              <ButtonLink
                href={`/applications/${application.id}`}
                intent="primary"
                emphasis="outlined"
                width="fill"
              >
                View Application
              </ButtonLink>
            )}
          </Card.Footer>
        </Card>
      ))}
    </Flex>
  )
}
