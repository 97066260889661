import styled from '@emotion/styled'
import { Flex, HelpText, Text } from '@applyboard/crystal-ui'
import {
  CloseOutlineIcon,
  DocumentCheckOutlineIcon,
  DocumentOutlineIcon,
  DownloadOutlineIcon,
  ImageOutlineIcon,
  PlayArrowOutlineIcon,
} from '@applyboard/ui-icons'
import { FileHistoryDialog } from '../FileHistoryDialog'
import { format } from 'date-fns'
import { IApplicationDocument } from '../ApplicationDocuments'

type ApplicationFileLinkProps = {
  file: IApplicationDocument
  helpText?: string
  onDelete?: (fileId: string) => void
  showHistory?: boolean
}

type FileType = 'image' | 'video' | 'document' | null

export function ApplicationFileLink(props: ApplicationFileLinkProps) {
  const intent = 'secondary'

  return (
    <ListElement>
      <Flex direction="column">
        <Flex direction="row" gap={6}>
          <FileChip intent={intent}>
            <FileLabel fileName={props.file.name} intent={intent} link={props.file.url || ''}>
              <FileIcon
                fileType={getFileTypeFromName(props.file.name)}
                intent={intent}
                link={props.file.url}
              />
              <Flex direction="column">
                <Text variant="labelM" intent={intent}>
                  {props.file.name}
                </Text>
                {!!props.helpText ? <HelpText intent={intent}>{props.helpText}</HelpText> : null}
              </Flex>
            </FileLabel>
            {!!props.onDelete ? (
              <CloseButton
                aria-label={`Remove file ${props.file.name}`}
                type="button"
                intent={intent}
                onClick={() => {
                  if (props.onDelete) {
                    props.onDelete(props.file.id)
                  }
                }}
              >
                <CloseOutlineIcon intent={intent} />
              </CloseButton>
            ) : null}
          </FileChip>
          {props.showHistory && props.file.history.length > 0 ? (
            <FileHistoryDialog historicalRecord={[props.file].concat(props.file.history)} />
          ) : null}
        </Flex>
        {!props.file.isBlankDatePlaceholder() ? (
          <Text contrast="low" variant={'labelS'}>
            {`Uploaded on ${format(props.file.uploadDate, 'dd MMM yyyy')}`}
          </Text>
        ) : null}
      </Flex>
    </ListElement>
  )
}

function getFileTypeFromName(name: string) {
  const parts = name.split('.')
  const extension = parts[parts.length - 1]
  return ['png', 'jpeg', 'jpg', 'webp', 'gif'].includes(extension)
    ? 'image'
    : ['doc', 'docx', 'pdf', 'txt', 'odt', 'rtf', 'epub'].includes(extension)
      ? 'document'
      : ['mp4', 'mov', 'avi', 'wmv', 'm4v'].includes(extension)
        ? 'video'
        : null
}

export function FileIcon({
  fileType,
  intent,
  link,
}: {
  fileType: FileType
  intent: 'secondary' | 'negative'
  link?: string
}) {
  const Icon = !!link
    ? DownloadOutlineIcon
    : fileType === 'image'
      ? ImageOutlineIcon
      : fileType === 'video'
        ? PlayArrowOutlineIcon
        : fileType === 'document'
          ? DocumentCheckOutlineIcon
          : DocumentOutlineIcon

  return <Icon intent={intent} size="md" />
}

function FileLabel({
  children,
  fileName,
  intent,
  link,
}: {
  children: React.ReactNode
  fileName: string
  intent: 'secondary' | 'negative'
  link: string
}) {
  if (!link) {
    return <FileDiv intent={intent}>{children}</FileDiv>
  }

  return (
    <FileLink intent={intent} href={link} target="_blank" aria-label={`Download file ${fileName}`}>
      {children}
    </FileLink>
  )
}

const ListElement = styled.li({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  width: '100%',
})

const FileChip = styled.div((props: { intent: string }) => ({
  alignItems: 'center',
  backgroundColor: props.intent === 'negative' ? 'rgb(254, 246, 248)' : 'rgb(246, 248, 254)',
  borderRadius: '8px',
  display: 'flex',
  width: '100%',
  height: '48px',
  justifyContent: 'space-between',
}))

const FileLink = styled.a((props: { intent: string }) => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  flexGrow: 1,
  gap: '16px',
  paddingLeft: '16px',
  borderRadius: '8px 0 0 8px',
  textDecoration: 'none',
  '&:hover': {
    backgroundColor: props.intent === 'negative' ? 'rgb(254, 232, 238)' : 'rgb(234, 238, 247)',
  },
}))

const FileDiv = styled.div((props: { intent?: string }) => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  flexGrow: 1,
  gap: '16px',
  paddingLeft: '16px',
  borderRadius: '8px 0 0 8px',
  textDecoration: 'none',
}))

const CloseButton = styled.button((props: { intent: string }) => ({
  borderRadius: '0px 8px 8px 0px',
  minWidth: 'unset',
  position: 'relative',
  whiteSpace: 'nowrap',
  borderColor: 'transparent',
  borderWidth: '1px',
  borderStyle: 'solid',
  backgroundColor: 'transparent',
  boxShadow: 'none',
  height: '48px',
  width: '48px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: props.intent === 'negative' ? 'rgb(254, 232, 238)' : 'rgb(234, 238, 247)',
  },
}))
