import {
  Checkbox,
  Combobox,
  createDatePickerValidator,
  createForm,
  DatePicker,
  Flex,
  Heading,
  Select,
  TextInput,
} from '@applyboard/crystal-ui'
import { isEqual } from 'lodash'
import {
  COUNTRY_NAMES,
  CountryIsoCode,
} from 'schools-domain-backend-utils/dist/common-types/country'
import { GENDER_NAMES, GenderCode } from 'schools-domain-backend-utils/dist/common-types/gender'
import { RawApplicationResponse } from '../../../hooks/useGetApplication'
import { useUpdateApplication } from '../../../hooks/useUpdateApplication'
import { GenericError } from '../../../utils'
import { convertTimelessDateStrToLocalDate } from '../../../utils/convertTimelessDateStrToLocalDate'
import { StudentApplication } from '../types'

type PersonalInformationFormFields = {
  givenName: string
  middleName: string
  familyName: string

  dateOfBirth: string
  nationality: string
  gender: string
  residentialAddressCountry: string
  residentialAddressLine1: string
  residentialAddressLine2: string
  residentialAddressCity: string
  residentialAddressProvince: string
  residentialAddressPostalCode: string
  mailingAddressCountry: string
  mailingAddressLine1: string
  mailingAddressLine2: string
  mailingAddressCity: string
  mailingAddressProvince: string
  mailingAddressPostalCode: string
  isMailingAddressResidentialAddress: boolean
}

const { Form, Field, useFieldValues, useSetFieldValues } =
  createForm<PersonalInformationFormFields>()

type PersonalInformationTabProps = {
  disabled?: boolean
  application: StudentApplication
  onSubmit?: () => void
  onSuccess: (response?: RawApplicationResponse) => void
  onError: (err: GenericError) => void
  updateApplication: ReturnType<typeof useUpdateApplication>['updateApplication']
  formId: string
}

export function PersonalInformationTab(props: PersonalInformationTabProps) {
  return (
    <Form
      id={props.formId}
      defaultValues={{
        givenName:
          props.application?.attributes?.personalInformation?.basicPersonalInformation?.givenName ||
          '',
        middleName:
          props.application?.attributes?.personalInformation?.basicPersonalInformation
            ?.middleName || '',
        familyName:
          props.application?.attributes?.personalInformation?.basicPersonalInformation
            ?.familyName || '',

        dateOfBirth: props.application?.attributes?.personalInformation?.basicPersonalInformation
          ?.dateOfBirth
          ? convertTimelessDateStrToLocalDate(
              props.application.attributes.personalInformation?.basicPersonalInformation
                ?.dateOfBirth,
            ).toISOString()
          : '',
        nationality:
          props.application?.attributes?.personalInformation?.basicPersonalInformation
            ?.nationality || '',
        gender:
          props.application?.attributes?.personalInformation?.basicPersonalInformation?.gender ||
          '',
        residentialAddressCountry:
          props.application?.attributes?.personalInformation?.addresses?.residentialAddress
            ?.country || '',
        residentialAddressLine1:
          props.application?.attributes?.personalInformation?.addresses?.residentialAddress
            ?.addressLine1 || '',
        residentialAddressLine2:
          props.application?.attributes?.personalInformation?.addresses?.residentialAddress
            ?.addressLine2 || '',
        residentialAddressCity:
          props.application?.attributes?.personalInformation?.addresses?.residentialAddress?.city ||
          '',
        residentialAddressProvince:
          props.application?.attributes?.personalInformation?.addresses?.residentialAddress
            ?.province || '',
        residentialAddressPostalCode:
          props.application?.attributes?.personalInformation?.addresses?.residentialAddress
            ?.postalCode || '',
        isMailingAddressResidentialAddress: areAddressesEqual({
          application: props.application,
        }),
        mailingAddressCountry:
          props.application?.attributes?.personalInformation?.addresses?.mailingAddress?.country ||
          '',
        mailingAddressLine1:
          props.application?.attributes?.personalInformation?.addresses?.mailingAddress
            ?.addressLine1 || '',
        mailingAddressLine2:
          props.application?.attributes?.personalInformation?.addresses?.mailingAddress
            ?.addressLine2 || '',
        mailingAddressCity:
          props.application?.attributes?.personalInformation?.addresses?.mailingAddress?.city || '',
        mailingAddressProvince:
          props.application?.attributes?.personalInformation?.addresses?.mailingAddress?.province ||
          '',
        mailingAddressPostalCode:
          props.application?.attributes?.personalInformation?.addresses?.mailingAddress
            ?.postalCode || '',
      }}
      onSubmit={data => {
        if (props.disabled) {
          props.onSuccess()
        } else {
          props.updateApplication(
            {
              attributes: {
                personalInformation: {
                  basicPersonalInformation: {
                    givenName: data?.givenName,
                    middleName: data?.middleName,
                    familyName: data?.familyName,

                    dateOfBirth: data?.dateOfBirth?.substring(0, 10),
                    nationality: (data?.nationality as CountryIsoCode) || null,
                    gender: (data?.gender as GenderCode) || null,
                  },
                  addresses: {
                    residentialAddress: {
                      country: data.residentialAddressCountry as CountryIsoCode,
                      addressLine1: data.residentialAddressLine1,
                      addressLine2: data.residentialAddressLine2,
                      city: data.residentialAddressCity,
                      province: data.residentialAddressProvince,
                      postalCode: data.residentialAddressPostalCode,
                    },
                    mailingAddress: {
                      country: data.mailingAddressCountry as CountryIsoCode,
                      addressLine1: data.mailingAddressLine1,
                      addressLine2: data.mailingAddressLine2,
                      city: data.mailingAddressCity,
                      province: data.mailingAddressProvince,
                      postalCode: data.mailingAddressPostalCode,
                    },
                  },
                },
              },
            },
            {
              onSuccess: props.onSuccess,
              onError: props.onError,
            },
          )
        }
      }}
    >
      <PersonalInformationTabFields disabled={props.disabled} application={props.application} />
    </Form>
  )
}

type PersonalInformationFormFieldsProps = {
  disabled?: boolean
  application: StudentApplication
}

function PersonalInformationTabFields(props: PersonalInformationFormFieldsProps) {
  const {
    isMailingAddressResidentialAddress,
    residentialAddressCountry,
    residentialAddressLine1,
    residentialAddressLine2,
    residentialAddressCity,
    residentialAddressProvince,
    residentialAddressPostalCode,
  } = useFieldValues([
    'isMailingAddressResidentialAddress',
    'residentialAddressCountry',
    'residentialAddressLine1',
    'residentialAddressLine2',
    'residentialAddressCity',
    'residentialAddressProvince',
    'residentialAddressPostalCode',
  ])
  const setFieldValues = useSetFieldValues()
  const maxDate = new Date()
  const minDate = new Date(1900, 0, 1)

  const datePickerValidator = createDatePickerValidator(minDate, maxDate, 'day')

  return (
    <>
      <Flex gap={4} direction={{ xs: 'column', sm: 'row' }} wrap>
        <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
          <Field
            as={TextInput}
            label="Given name"
            name="givenName"
            disabled={props.disabled}
            required={!props.disabled ? 'Given name is required' : false}
            helpText="Full legal name as displayed on your passport"
          />
        </Flex.Item>
        <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
          <Field
            as={TextInput}
            label="Family name (if applicable)"
            name="familyName"
            disabled={props.disabled}
          />
        </Flex.Item>
        <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
          <Field as={TextInput} label="Middle name" name="middleName" disabled={props.disabled} />
        </Flex.Item>
        <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
          <Field
            as={Select}
            label="Gender"
            name="gender"
            appearance="styled"
            disabled={props.disabled}
            required={!props.disabled ? 'Gender is required' : false}
          >
            {Object.entries(GENDER_NAMES).map(([genderCode, genderName]) => (
              <Select.Option key={genderCode} label={genderName} value={genderCode} />
            ))}
          </Field>
        </Flex.Item>
        <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
          <Field
            as={DatePicker}
            label="Date of birth"
            name="dateOfBirth"
            maxDate={maxDate.toISOString()}
            minDate={minDate.toISOString()}
            disabled={props.disabled}
            required={!props.disabled ? 'Date of birth is required' : false}
            validate={datePickerValidator}
          />
        </Flex.Item>
        <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
          <Field
            as={Combobox}
            label="Nationality"
            name="nationality"
            size="md"
            placeholder="Select"
            disabled={props.disabled}
            required={!props.disabled ? 'Nationality is required' : false}
          >
            {Object.entries(COUNTRY_NAMES).map(([countryCode, countryName]) => (
              <Combobox.Option key={countryCode} label={countryName} value={countryCode} />
            ))}
          </Field>
        </Flex.Item>
      </Flex>
      <Flex gap={4} direction={{ xs: 'column', sm: 'row' }} wrap pt={4} pb={6}>
        <Flex basis="100%">
          <Heading variant="titleS" level={3}>
            <span aria-hidden>🏢</span> Residential Address
          </Heading>
        </Flex>
        <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
          <Field
            as={Combobox}
            label="Country"
            name="residentialAddressCountry"
            size="md"
            placeholder="Select"
            disabled={props.disabled}
            required={!props.disabled ? 'Country is required' : false}
            onChange={value => {
              if (isMailingAddressResidentialAddress) {
                setFieldValues({
                  ...copyResidentialAddress({
                    residentialAddressCountry,
                    residentialAddressLine1,
                    residentialAddressLine2,
                    residentialAddressCity,
                    residentialAddressProvince,
                    residentialAddressPostalCode,
                  }),
                  mailingAddressCountry: value as string,
                })
              }
            }}
          >
            {Object.entries(COUNTRY_NAMES).map(([countryCode, countryName]) => (
              <Combobox.Option key={countryCode} label={countryName} value={countryCode} />
            ))}
          </Field>
        </Flex.Item>
        <Flex.Item basis={{ xs: '100%' }}>
          <Field
            as={TextInput}
            label="Address line 1"
            name="residentialAddressLine1"
            disabled={props.disabled}
            required={!props.disabled ? 'Address is required' : false}
            helpText="E.g. Street address"
            onChange={value => {
              if (isMailingAddressResidentialAddress) {
                setFieldValues({
                  ...copyResidentialAddress({
                    residentialAddressCountry,
                    residentialAddressLine1,
                    residentialAddressLine2,
                    residentialAddressCity,
                    residentialAddressProvince,
                    residentialAddressPostalCode,
                  }),
                  mailingAddressLine1: value as string,
                })
              }
            }}
          />
        </Flex.Item>
        <Flex.Item basis={{ xs: '100%' }}>
          <Field
            as={TextInput}
            label="Address line 2"
            name="residentialAddressLine2"
            disabled={props.disabled}
            helpText="E.g. Apartment, suite, unit"
            onChange={value => {
              if (isMailingAddressResidentialAddress) {
                setFieldValues({
                  ...copyResidentialAddress({
                    residentialAddressCountry,
                    residentialAddressLine1,
                    residentialAddressLine2,
                    residentialAddressCity,
                    residentialAddressProvince,
                    residentialAddressPostalCode,
                  }),
                  mailingAddressLine2: value as string,
                })
              }
            }}
          />
        </Flex.Item>
        <Flex basis="100%" gap={4}>
          <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
            <Field
              as={TextInput}
              label="Province/state/region"
              name="residentialAddressProvince"
              disabled={props.disabled}
              required={!props.disabled ? 'Province/state/region is required' : false}
              onChange={value => {
                if (isMailingAddressResidentialAddress) {
                  setFieldValues({
                    ...copyResidentialAddress({
                      residentialAddressCountry,
                      residentialAddressLine1,
                      residentialAddressLine2,
                      residentialAddressCity,
                      residentialAddressProvince,
                      residentialAddressPostalCode,
                    }),
                    mailingAddressProvince: value as string,
                  })
                }
              }}
            />
          </Flex.Item>
          <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
            <Field
              as={TextInput}
              label="City/town"
              name="residentialAddressCity"
              disabled={props.disabled}
              required={!props.disabled ? 'City/town is required' : false}
              onChange={value => {
                if (isMailingAddressResidentialAddress) {
                  setFieldValues({
                    ...copyResidentialAddress({
                      residentialAddressCountry,
                      residentialAddressLine1,
                      residentialAddressLine2,
                      residentialAddressCity,
                      residentialAddressProvince,
                      residentialAddressPostalCode,
                    }),
                    mailingAddressCity: value as string,
                  })
                }
              }}
            />
          </Flex.Item>
        </Flex>
        <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
          <Field
            as={TextInput}
            label="Postal/zip code"
            name="residentialAddressPostalCode"
            disabled={props.disabled}
            onChange={value => {
              if (isMailingAddressResidentialAddress) {
                setFieldValues({
                  ...copyResidentialAddress({
                    residentialAddressCountry,
                    residentialAddressLine1,
                    residentialAddressLine2,
                    residentialAddressCity,
                    residentialAddressProvince,
                    residentialAddressPostalCode,
                  }),
                  mailingAddressPostalCode: value as string,
                })
              }
            }}
          />
        </Flex.Item>
      </Flex>
      <Heading variant="titleS" level={3}>
        <span aria-hidden>✉️</span> Full Mailing Address
      </Heading>

      <Flex>
        <Field
          as={Checkbox}
          name="isMailingAddressResidentialAddress"
          label="Mailing address is the same as residential address"
          disabled={props.disabled}
          onChange={value => {
            if (!value) {
              setFieldValues({
                mailingAddressCountry: '',
                mailingAddressLine1: '',
                mailingAddressLine2: '',
                mailingAddressCity: '',
                mailingAddressProvince: '',
                mailingAddressPostalCode: '',
              })
            } else {
              setFieldValues(
                copyResidentialAddress({
                  residentialAddressCountry,
                  residentialAddressLine1,
                  residentialAddressLine2,
                  residentialAddressCity,
                  residentialAddressProvince,
                  residentialAddressPostalCode,
                }),
              )
            }
          }}
        />
      </Flex>

      {!isMailingAddressResidentialAddress ? (
        <>
          <Flex>
            <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
              <Field
                as={Combobox}
                label="Country"
                name="mailingAddressCountry"
                size="md"
                placeholder="Select"
                disabled={props.disabled}
                required={!props.disabled ? 'Country is required' : false}
              >
                {Object.entries(COUNTRY_NAMES).map(([countryCode, countryName]) => (
                  <Combobox.Option key={countryCode} label={countryName} value={countryCode} />
                ))}
              </Field>
            </Flex.Item>
          </Flex>
          <Flex pb={6} gap={4} direction={{ xs: 'column', sm: 'row' }} wrap>
            <Flex.Item basis={{ xs: '100%' }}>
              <Field
                as={TextInput}
                label="Address line 1"
                name="mailingAddressLine1"
                disabled={props.disabled}
                required={!props.disabled ? 'Address is required' : false}
                helpText="E.g. Street address"
              />
            </Flex.Item>
            <Flex.Item basis={{ xs: '100%' }}>
              <Field
                as={TextInput}
                label="Address line 2"
                name="mailingAddressLine2"
                disabled={props.disabled}
                helpText="E.g. Apartment, suite, unit"
              />
            </Flex.Item>
            <Flex basis="100%" gap={4}>
              <Flex.Item basis={{ xs: 'calc(50% - 8px)' }}>
                <Field
                  as={TextInput}
                  label="Province/state/region"
                  name="mailingAddressProvince"
                  disabled={props.disabled}
                  required={!props.disabled ? 'Province/state/region is required' : false}
                />
              </Flex.Item>
              <Flex.Item basis={{ xs: 'calc(50% - 8px)' }}>
                <Field
                  as={TextInput}
                  label="City/town"
                  name="mailingAddressCity"
                  disabled={props.disabled}
                  required={!props.disabled ? 'City/town is required' : false}
                />
              </Flex.Item>
            </Flex>
            <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
              <Field
                as={TextInput}
                label="Postal/zip code"
                name="mailingAddressPostalCode"
                disabled={props.disabled}
              />
            </Flex.Item>
          </Flex>
        </>
      ) : null}
    </>
  )
}

type areAddressesEqualParams = {
  application: StudentApplication
}

function copyResidentialAddress({
  residentialAddressCountry,
  residentialAddressLine1,
  residentialAddressLine2,
  residentialAddressCity,
  residentialAddressProvince,
  residentialAddressPostalCode,
}: {
  residentialAddressCountry: string
  residentialAddressLine1: string
  residentialAddressLine2: string
  residentialAddressCity: string
  residentialAddressProvince: string
  residentialAddressPostalCode: string
}) {
  return {
    mailingAddressCountry: residentialAddressCountry,
    mailingAddressLine1: residentialAddressLine1,
    mailingAddressLine2: residentialAddressLine2,
    mailingAddressCity: residentialAddressCity,
    mailingAddressProvince: residentialAddressProvince,
    mailingAddressPostalCode: residentialAddressPostalCode,
  }
}

function areAddressesEqual(params: areAddressesEqualParams) {
  if (
    !params.application?.attributes?.personalInformation?.addresses ||
    isEqual(
      params.application.attributes?.personalInformation?.addresses?.residentialAddress,
      params.application.attributes?.personalInformation?.addresses?.mailingAddress,
    )
  ) {
    return true
  }
  return false
}
