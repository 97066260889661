import { Tag, TagProps } from '@applyboard/crystal-ui'
import { ApplicationState } from 'applications-types-lib'
import { ApplicationState as AppStatus } from '../../../hooks'

type ApplicationStateTagProps = {
  state?: ApplicationState
  hasOpenDocumentRequests?: boolean
}

export default function ApplicationStateTag(props: ApplicationStateTagProps) {
  const getStateInfo = (hasOpenDocumentRequests?: boolean) => {
    if (hasOpenDocumentRequests) {
      return {
        intent: 'warning' as TagProps['intent'],
        label: 'Documents Requested',
      }
    }

    let intent = 'warning' as TagProps['intent']
    let label = 'Draft'

    switch (props.state) {
      case AppStatus.DECLINED:
        intent = 'negative'
        label = 'Declined'
        break
      case AppStatus.PAYMENT_PROCESSING:
        intent = 'magic' as TagProps['intent']
        label = 'Payment Processing'
        break
      case AppStatus.READY_FOR_SCREENING:
      case AppStatus.READY_FOR_ASSESSMENT:
        intent = 'primary'
        label = 'Submitted'
        break
      case AppStatus.INITIAL_OFFER:
        intent = 'positive'
        label = 'Pre-Offer'
        break
      case AppStatus.WAITLISTED:
        intent = 'secondary'
        label = 'Waitlist'
        break
      case AppStatus.FINAL_OFFER_ISSUED:
        intent = 'positive'
        label = 'Final Offer'
        break
      case AppStatus.DEFERRAL_REQUESTED_IN_ASSESSMENT:
      case AppStatus.DEFERRAL_REQUESTED_IN_SCREENING:
      case AppStatus.DEFERRAL_REQUESTED_IN_INITIAL_OFFER:
        intent = 'warning'
        label = 'Deferral Requested'
        break
    }

    return {
      intent,
      label,
    }
  }

  const { intent, label } = getStateInfo(props.hasOpenDocumentRequests)

  return (
    <Tag icon="fill" intent={intent}>
      {label}
    </Tag>
  )
}
